import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import "./toolBoxTalkList.scss";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from "../tabPanel/tabPanel";
import { useSelector, useDispatch } from "react-redux";
import {
  COLUMNS_ADMIN_TOOLBOXTALK_VIEW,
  COLUMNS_TOOLBOXTALKADMIN_VIEW,
  SCHEDULEDTYPELIST,
} from "../../courseConstant";
import TableRowAction from "../../../../library/custom/gridTable/TableRowAction";
import { useNavigate } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";
import { InactiveClient } from "../../../../utils/helper";

import {
  TOOL_BOX_ASSIGN,
  TOOL_BOX_DETAIL,
  TOOL_BOX_VERIFY_ATTENDEES,
  TOOL_BOX_ASSIGN_USER,
  TOOL_BOX_USERCOUNT_ASSIGN,
  TOOL_BOX_CREATE,
} from "../../../../constant/routeContant";
import { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import {
  PAGE_SIZE,
  ORG_ID,
  USER_ID,
  ROW_PER_PAGE_SIZE,
} from "../../../../constant/constant";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import DialogBox from "../../../../library/common/DialogBox";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import * as toolboxAction from "../../../../redux/actions/toolBoxAction";
import { useLocation } from "react-router-dom";
import FilterCommonStatusSelect from "../../../admin/shared/FilterCommonStatusSelect";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import { getFormatedDate } from "../../../../utils/helper";

const ToolBoxTalkAdminList = (props) => {
  const location = useLocation();
  const backTabId = location?.state?.backTabId;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [value, setValue] = React.useState(backTabId ? backTabId : 0);
  const dispatch = useDispatch();
  const ToolBoxTalkListAdminData = props?.ToolBoxTalkListData;
  const getToolBoxTalkListAdmin = props?.getToolBoxTalkListAdmin;
  const deleteAdminToolBoxTalks = props?.deleteAdminToolBoxTalks;
  const getToolBoxTalkList = props?.getToolBoxTalkList;
  const ToolBoxTalkListDataSchedule = props?.ToolBoxTalkListDataSchedule;

  const [isTableLoading, setisTableLoading] = useState(false);
  useEffect(() => {
    if (props?.tabId) setValue(props?.tabId);
  }, [props?.tabId]);

  const handleChange = (event, newValue) => {
    setFilter({
      ...filter,
      toDate: "",
      fromDate: "",
      searchType: "",
      isAssigned: "",
    });
    setSearch("");
    if (newValue === 0) {
      setPage(1);
      setValue(newValue);
      const { searchType } = filter;
      const data = {
        pageNumber: 1,
        pageSize: pageSize,
        search: "",
        catIds: "",
        userId: "",
        tabId: "1",

        categoryIds: filter?.searchType,
        fromDate: filter.fromDate,
        toDate: filter.toDate,
      };
      getToolBoxTalkListAdmin(data);
    } else if (newValue === 1) {
      setPage(1);
      setValue(newValue);
      const { searchType } = filter;
      const data = {
        pageNumber: 1,
        pageSize: pageSize,
        search: "",
        catIds: "",
        userId: 0,
        tabId: "0",

        categoryIds: filter?.searchType,
        fromDate: filter.fromDate,
        toDate: filter.toDate,
        isAssigned: "",
      };
      getToolBoxTalkList(data);
    } else if (newValue === 2) {
      setPage(1);
      setValue(newValue);
      const data = {
        pageNumber: 1,
        pageSize: pageSize,
        search: "",
        catIds: "",
        userId: 0,
        tabId: "1",
        categoryIds: filter?.searchType,
        fromDate: filter.fromDate,
        toDate: filter.toDate,
        isAssigned: "",
      };
      getToolBoxTalkList(data);
    }

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
  };
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const { isLoading } = useSelector((state) => state.getToolBoxTalkList);
  const categoryListData = props?.categoryListData;
  const noRecourdCount = ToolBoxTalkListAdminData?.itemsCount;
  const noRecourdScheduleCount = ToolBoxTalkListDataSchedule?.itemsCount;

  const handleOnCellClick = (params) => {
    let toolboxTalkId = params?.id;
    let fieldName = params?.field;
    let scheduleId = params?.scheduleId;
    let finalid;
    if (fieldName === "titleSafetyMeeting") {
      finalid = "true";
    } else {
      finalid = "false";
    }

    if (fieldName === "titleSafetyMeeting") {
      dispatch(toolboxAction.clearToolBoxTalk());
      navigate(TOOL_BOX_DETAIL, {
        state: {
          fieldId: finalid,
          toolboxTalkId: toolboxTalkId,
          scheduleId: scheduleId,
          page: "safetyMeetingManagement",
          isCurrentActiveContent: true,
        },
      });
    }
  };

  const handleOnCellClickSchedule = (params) => {
    let toolboxTalkId = params?.row?.toolboxTalkId;
    let fieldName = params?.field;
    let scheduleId = params?.row?.scheduleId;
    let assignedOnAdmin = params?.row?.assignedOn;
    let title = params?.row?.titleSafetyMeeting;
    let finalid;
    let toolboxTalkVersionId = params?.row?.toolboxTalkVersionId;
    if (
      // fieldName === "titleSafetyMeeting" ||
      // fieldName === "totaleAssignedTo" ||
      fieldName === "totalUserCompetedBy"
    ) {
      finalid = "true";
    } else {
      finalid = "false";
    }
    if (fieldName === "titleSafetyMeeting" && value === 1) {
      dispatch(toolboxAction.clearToolBoxTalk());
      navigate(TOOL_BOX_DETAIL, {
        state: {
          fieldId: finalid,
          toolboxTalkId: toolboxTalkId,
          scheduleId: scheduleId,
          tabvalue: value,
          toolboxTalkVersionId: toolboxTalkVersionId,
          isCurrentActiveContent: false,
        },
      });
    }
    // TODO:
    if (fieldName === "totalAssignedTo") {
      navigate(TOOL_BOX_USERCOUNT_ASSIGN, {
        state: {
          fieldId: finalid,
          flag: "0",
          scheduleId: scheduleId,
          scheduleDate: assignedOnAdmin,
          title: title,
          tabvalue: value,
          filterBy: 1,
          toolboxTalkId: toolboxTalkId,
          toolboxTalkVersionId: toolboxTalkVersionId,
        },
      });
    }
    if (fieldName === "totalUserCompetedBy") {
      navigate(TOOL_BOX_USERCOUNT_ASSIGN, {
        state: {
          fieldId: finalid,
          flag: "1",
          scheduleId: scheduleId,
          scheduleDate: assignedOnAdmin,
          title: title,
          tabvalue: value,
          filterBy: params?.row?.isScheduled ? 2 : 4,
          toolboxTalkId: toolboxTalkId,
          toolboxTalkVersionId: toolboxTalkVersionId,
        },
      });
    }
    // TODO:
    if (fieldName === "totalUserNotCompetedBy") {
      navigate(TOOL_BOX_USERCOUNT_ASSIGN, {
        state: {
          fieldId: finalid,
          flag: "2",
          scheduleId: scheduleId,
          scheduleDate: assignedOnAdmin,
          title: title,
          tabvalue: value,
          filterBy: 3,
          toolboxTalkId: toolboxTalkId,
          toolboxTalkVersionId: toolboxTalkVersionId,
        },
      });
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [selectedEditRow, setSelectedEditRow] = useState("");
  const [isOnce, setisOnce] = useState(false);

  const deleteItem = (rows) => {
    if (InactiveClient()) return;
    setisOnce(rows.frequencyId === 5 ? true : false);
    setTimeout(() => {
      setOpenModal(true);
      setSelectedRow(rows);
    }, 1);
  };

  const editItem = (rows) => {
    if (InactiveClient()) return;
    setOpenScheduleModal(true);
    setSelectedEditRow(rows);
  };

  const deletehandle = () => {
    if (InactiveClient()) return;
    setOpenModal(false);

    const data = {
      toolboxTalkId: selectedRow?.id,
    };
    deleteAdminToolBoxTalks(data);
  };
  const deletehandleSchedule = (val) => {
    if (InactiveClient()) return;
    setOpenModal(false);
    const data = {
      scheduleId: selectedRow?.scheduleId,
      effectiveDate: isOnce || val === 1 ? selectedRow?.assignedOn : null,
      instructorUserContractor: null,
    };

    props?.deleteScheduleToolBoxTalk(data);
  };

  function handleEditMeeting(params) {
    if (InactiveClient()) return;
    navigate(TOOL_BOX_CREATE, {
      state: { toolboxId: params, editMode: true },
    });
  }

  const columns = React.useMemo(() => {
    const headers = [...COLUMNS_ADMIN_TOOLBOXTALK_VIEW];
    headers.push({
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={noRecourdCount ?? 0}
          numberOfItemsPerPage={10}
          incidentLists={ToolBoxTalkListAdminData?.saftyMeetingList ?? []}
          fileNameXL={"SafetyMeetings"}
        />
      ),
      width: 70,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <TableRowAction
            rows={rows}
            usingComp={"Meeting"}
            ScheduleMeetings
            deleted={rows.row.isPublished ? "" : "deleted"}
            ScheduleAttendeeLabel={"Schedule Meeting"}
            deleteLabel={"Delete Meeting"}
            scheduleMeetingOnclick={() => handleScheduleMeetingOnclick(rows)}
            deleteOnClick={() =>
              checkPrivileges([12, 90]) ? deleteItem(rows?.row) : ""
            }
            view={true}
            viewLabel={"View Meeting"}
            viewOnClick={(row) =>
              handleOnCellClick({
                id: rows?.row?.id,
                field: "titleSafetyMeeting",
              })
            }
            edit={true}
            editLabel={"Edit Meeting"}
            editOnClick={(toolboxids) => handleEditMeeting(rows?.row?.id)}
          />
        );
      },
    });
    return headers;
  }, [ToolBoxTalkListAdminData?.saftyMeetingList, pageSize, navigate]);
  const handleClickAssign = (data) => {
    navigate(TOOL_BOX_ASSIGN_USER, {
      state: {
        tabId: value,
        scheduleId: data?.row?.id,
        assignedOnAdmin: data?.row?.assignedOn,
      },
    });
  };
  const handleViewAttendeeOnClick = (row) => {
    const assignedOn = row?.assignedOn;
    const toolboxTalkId = row?.toolboxTalkId;
    const scheduleId = row?.id;

    navigate(TOOL_BOX_VERIFY_ATTENDEES, {
      state: {
        assignedOn: assignedOn,
        toolboxTalkId: toolboxTalkId,
        scheduleId: scheduleId,
      },
    });
  };
  function handleEditInstrcutor(params) {
    navigate(TOOL_BOX_ASSIGN, {
      state: {
        scheduleId: params.scheduleId,
        scheduleDate: params.assignedOn,
        userId: 0,
        editMode: true,
        editInstructorStatus: 1,
      },
    });
  }
  const columnsSchedule = React.useMemo(() => {
    const headers = [...COLUMNS_TOOLBOXTALKADMIN_VIEW];

    headers.push(
      value === 1
        ? {
          field: "icon",
          renderHeader: () => (
            <SettingTable
              noOfRecords={noRecourdScheduleCount ?? 0}
              numberOfItemsPerPage={10}
              incidentLists={
                ToolBoxTalkListDataSchedule?.saftyMeetingList ?? []
              }
              fileNameXL={"SafetyMeetings"}
            />
          ),
          width: 70,
          sortable: false,
          disableClickEventBubbling: true,
          renderCell: (rows) => {
            return (
              <TableRowAction
                rows={rows}
                usingComp={"Meeting"}
                // VerifyAttendees
                viewAttendeeLabel={"Verify Attendees"}
                deleted={rows.row.isPublished ? "" : "deleted"}
                deleteLabel={"Delete Meeting"}
                // viewAttendee
                // AssignAttendees
                assignLabel={"Assign Attendees"}
                edit={value === 1}
                editLabel={"Edit Instructors"}
                editOnClick={(row) =>
                  row.frequencyId === 5
                    ? handleEditInstrcutor(row)
                    : editItem(rows)
                }
                // editOnClick={() => editItem(rows)}
                AssignAttendeeOnClick={() => handleClickAssign(rows)}
                viewOnAttendeeClick={(row) => handleViewAttendeeOnClick(row)}
                // deleteOnClick={() =>
                //   checkPrivileges([12, 90]) ? deleteItem(rows?.row) : ""
                // }
                deleteOnClick={() =>
                  checkPrivileges([12, 90]) ? deleteItem(rows?.row) : ""
                }
                vi={true}
                viewLabel={"View"}
                viewOnClick={(row) => handleOnCellClick(row)}
              />
            );
          },
        }
        : { field: " ", headerName: " ", width: 10 }
    );
    return headers;
  }, [ToolBoxTalkListDataSchedule?.saftyMeetingList, pageSize, navigate]);

  const columnsCompleted = React.useMemo(() => {
    const headers = [
      { field: "toolboxTalkId", headerName: "ID", width: 100 },
      {
        field: "titleSafetyMeeting",
        headerName: "Safety Meeting Name",
        flex: 2,
        // cellClassName: "ToolBoxTitle-assigned-to--cell",
      },

      { field: "assignedOn", headerName: "Assigned On", flex: 1 },
      { field: "categoryName", headerName: "Category", flex: 1 },
      {
        field: "isScheduled",
        headerName: "Scheduled",
        flex: 1,
        renderCell: (rows) => (
          <Grid className="catListBox">
            {rows?.row.isScheduled ? "Yes" : "No"}
          </Grid>
        ),
      },
      {
        field: "totalAssignedTo",
        headerName: "Assigned to",
        type: "text",
        flex: 1,
        cellClassName: "ToolBoxTitle-assigned-to--cell",
      },
      {
        field: "totalUserCompetedBy",
        headerName: "Completed By",
        type: "text",
        flex: 1,
        cellClassName: "ToolBoxTitle-assigned-to--cell",
      },
      {
        field: "totalUserNotCompetedBy",
        headerName: "Not Completed By",
        type: "text",
        flex: 1,
        cellClassName: "ToolBoxTitle-assigned-to--cell",
      },
      { field: " ", headerName: " ", width: 10 },
    ];
    return headers;
  }, [ToolBoxTalkListDataSchedule?.saftyMeetingList, pageSize, navigate]);

  const handleScheduleMeetingOnclick = (rows) => {
    if (InactiveClient()) return;
    const toolboxid = rows?.id;
    const id = rows?.row?.id;
    const titleSafetyMeeting = rows?.row?.titleSafetyMeeting;

    navigate(TOOL_BOX_ASSIGN, {
      state: {
        id: id,
        toolboxid: toolboxid,
        titleSafetyMeeting: titleSafetyMeeting,
        editMode: false,
      },
    });
  };

  const handlePagignation = (newPage) => {
    if (value == 0) {
      setPage(newPage + 1);
      getToolBoxTalkListAdmin({
        pageNumber: newPage + 1,
        pageSize: pageSize,
        search: search,
        userId: "",
        organisationId: ORG_ID(),
        tabId: value === 1 ? "0" : "1",
        categoryIds: filter?.searchType,
        fromDate: filter.fromDate,
        toDate: filter.toDate,
        // tabId: "1",
      });
    } else {
      setPage(newPage + 1);
      getToolBoxTalkList({
        pageNumber: newPage + 1,
        pageSize: pageSize,
        search: search,
        userId: "",
        organisationId: ORG_ID(),
        tabId: value === 1 ? "0" : "1",
        categoryIds: filter?.searchType,
        fromDate: filter.fromDate,
        toDate: filter.toDate,
        isAssigned:
          filter.isAssigned === 1 ? true : filter.isAssigned === 2 ? false : "",
      });
    }
  };

  const handlePageSizeChange = (newPazeSize) => {
    if (value == 0) {
      setPageSize(newPazeSize);
      getToolBoxTalkListAdmin({
        pageNumber: page,
        pageSize: newPazeSize,
        search: search,
        userId: "",
        organisationId: ORG_ID(),
        tabId: value === 1 ? "0" : "1",
        categoryIds: filter?.searchType,
        fromDate: filter.fromDate,
        toDate: filter.toDate,
        // tabId: "1",
      });
    } else {
      setPageSize(newPazeSize);
      getToolBoxTalkList({
        pageNumber: page,
        pageSize: newPazeSize,
        search: search,
        userId: "",
        organisationId: ORG_ID(),
        tabId: value === 1 ? "0" : "1",
        categoryIds: filter?.searchType,
        fromDate: filter.fromDate,
        toDate: filter.toDate,
        //  isAssigned: filter.isAssigned === 1 ? true : false
        isAssigned:
          filter.isAssigned === 1 ? true : filter.isAssigned === 2 ? false : "",
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };
  const handleSearch = (data1) => {
    setAnchorEl(null);
    const data = {
      pageNumber: 1,
      pageSize: pageSize,
      search: search,
      userId: "",
      organisationId: ORG_ID(),
      categoryIds: filter?.searchType,
      fromDate: filter.fromDate,
      toDate: filter.toDate,
      tabId: value === 1 ? "0" : "1",
      isAssigned:
        filter.isAssigned === 1 ? true : filter.isAssigned === 2 ? false : "",
    };

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    if (value == 1 || value === 2) {
      getToolBoxTalkList(data);
    } else {
      getToolBoxTalkListAdmin(data);
    }
  };

  const [filter, setFilter] = useState({
    searchType: "",
    toDate: "",
    fromDate: "",
    isAssigned: "",
  });

  const onChangeType = (item) => {
    setFilter({
      ...filter,
      searchType: item.target.value,
    });
  };
  const onChangeToDate = (item) => {
    setFilter({
      ...filter,
      toDate: getFormatedDate(item),
    });
  };
  const onChangeFromDate = (item) => {
    setFilter({
      ...filter,
      fromDate: getFormatedDate(item),
    });
  };
  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      pageNumber: 1,
      pageSize: pageSize,
      search: searchText ? searchText : "",
      catIds: filter?.searchType ? filter?.searchType : "",
      userId: "",
      categoryIds: filter?.searchType,
      fromDate: filter.fromDate,
      toDate: filter.toDate,
      tabId: value === 1 ? "0" : "1",
      isAssigned:
        filter.isAssigned === 1 ? true : filter.isAssigned === 2 ? false : "",
    };

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    if (value === 1 || value === 2) {
      getToolBoxTalkList(data);
    } else {
      getToolBoxTalkListAdmin(data);
    }
  };
  ToolBoxTalkListDataSchedule?.saftyMeetingList.map((Dta, index) => {
    return (Dta["safetyid"] = (page - 1) * 5 + index + 1);
  });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClear = () => {
    setFilter({
      ...filter,
      searchType: "",
      toDate: "",
      fromDate: "",
      isAssigned: "",
    });
    setSearch("");
    const data = {
      pageNumber: 1,
      pageSize: pageSize,
      search: "",
      userId: "",
      organisationId: ORG_ID(),
      categoryIds: "",
      tabId: value === 1 ? "0" : "1",
      isAssigned: "",
    };
    setAnchorEl(false);

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    if (value == 1 || value === 2) {
      getToolBoxTalkList(data);
    } else {
      getToolBoxTalkListAdmin(data);
    }
  };
  const expirationTypeOnChanges = (item) => {
    setFilter({
      ...filter,
      isAssigned: item?.target?.value,
    });
  };
  const edithandleSchedule = (editInstructorStatus) => {
    navigate(TOOL_BOX_ASSIGN, {
      state: {
        scheduleId: selectedEditRow?.row?.scheduleId,
        scheduleDate: selectedEditRow?.row?.assignedOn,
        userId: parseInt(USER_ID()),
        editMode: true,
        editInstructorStatus: editInstructorStatus,
      },
    });
  };
  return (
    <>
      <div className="toolboxList">
        <Grid container>
          <Grid lg={7} xs={12}>
            {openModal && value === 0 && (
              <DialogBox
                open={openModal}
                rows={[]}
                header={"Confirm Deletion"}
                deletehandle={deletehandle}
                handleCloseModal={() => setOpenModal(false)}
                content={"Are you sure you want to delete this Safety Meeting?"}
                view
              />
            )}

            {openModal && value === 1 && (
              <DialogBox
                open={openModal}
                rows={[]}
                header={"Confirm Deletion"}
                handleJustscheduleAndEntirePopup={deletehandleSchedule}
                deletehandle={deletehandleSchedule}
                handleCloseModal={() => setOpenModal(false)}
                content={
                  isOnce
                    ? "Are you sure you want to delete this Schedule Safety Meeting?"
                    : "This Safety meeting has been scheduled in a series.What do you want to delete?"
                }
                view
                deleteView={isOnce ? true : false}
                scheduleJustAndEntirePopup={isOnce ? false : true}
                scheduleEdit={false}
              />
            )}
            {openScheduleModal && value === 1 && (
              <DialogBox
                open={openScheduleModal}
                rows={[]}
                header={"Confirm Edit"}
                handleJustscheduleAndEntirePopup={edithandleSchedule}
                handleCloseModal={() => setOpenScheduleModal(false)}
                content={
                  "This Safety meeting has been scheduled in a series.What do you want to edit?"
                }
                view
                deleteView={false}
                scheduleJustAndEntirePopup={true}
                scheduleEdit={true}
              />
            )}
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  className="tabcustom"
                  icon={
                    value === 0 ? (
                      <div className="totalV">
                        {value === 0 ? noRecourdCount : ""}
                      </div>
                    ) : (
                      ""
                    )
                  }
                  iconPosition="end"
                  label="All Safety Meetings"
                />
                <Tab
                  className="tabcustom"
                  icon={
                    value === 1 ? (
                      <div className="totalV">
                        {value === 1 ? noRecourdScheduleCount : ""}
                      </div>
                    ) : (
                      ""
                    )
                  }
                  iconPosition="end"
                  label="Scheduled Meetings"
                />
                <Tab
                  className="tabcustom"
                  icon={
                    value === 2 ? (
                      <div className="totalV">
                        {value === 2 ? noRecourdScheduleCount : ""}
                      </div>
                    ) : (
                      ""
                    )
                  }
                  iconPosition="end"
                  label="Past Meetings"
                />
              </Tabs>
            </Box>
          </Grid>
          <Grid lg={5} xs={12} mt={1}>
            <div className="filtertoolbox">
              <div className="filter_of_caselisttoolBox">
                <div className="searchcaseid">
                  <label htmlFor="search">
                    <span>
                      <SearchIcon />
                    </span>
                    <div>
                      <input
                        id="search"
                        placeholder="Search"
                        type="text"
                        value={search}
                        className=""
                        onChange={handleautoSearch}
                        autoComplete="off"
                      />
                    </div>
                  </label>
                </div>

                <div className="filter_btn">
                  <button
                    aria-describedby={id}
                    variant="contained"
                    type="button"
                    name="btn"
                    onClick={(e) => handleClick(e)}
                  >
                    <FilterListIcon id="icn" /> Filters
                  </button>
                  <FilterCommonStatusSelect
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    handleApply={handleSearch}
                    handleClear={handleClear}
                    filterDate={value !== 0}
                    filterSelect
                    filter={filter}
                    categoryType={categoryListData ?? []}
                    categoryOnChanges={onChangeType}
                    category={filter.searchType}
                    filterStatus={false}
                    resetBtn={true}
                    label={"Category"}
                    fromDate={filter.fromDate}
                    onchangeFromDatePicker={onChangeFromDate}
                    toDate={filter.toDate}
                    onchangeToDatePicker={onChangeToDate}
                    scheduledTypeOnChanges={expirationTypeOnChanges}
                    scheduledTypeList={SCHEDULEDTYPELIST}
                    isAssigned={filter?.isAssigned}
                    ispastMeetingTab={value == 2}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <TabPanel className="tabPanel" value={value} index={1}>
            <div className="desc_box row_uniq">
              {isTableLoading ? (
                ""
              ) : (
                <div className="table_style_ui">
                  <GridTable
                    getRowId={(r) => r.safetyid}
                    rows={ToolBoxTalkListDataSchedule?.saftyMeetingList ?? []}
                    rowCount={ToolBoxTalkListDataSchedule?.itemsCount ?? 0}
                    columns={columnsSchedule}
                    //loading={isLoading}
                    loading={props?.showLoaderSchedule}
                    page={page}
                    pagination
                    pageSize={pageSize}
                    rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                    onPageSizeChange={handlePageSizeChange}
                    onPageChange={handlePagignation}
                    backIconButtonProps={{ disabled: false }}
                    disableSelectionOnClick
                    components={{
                      LoadingOverlay: TableLoader,
                      NoRowsOverlay: NORow,
                    }}
                    onClick={handleOnCellClickSchedule}
                  />
                </div>
              )}
            </div>
          </TabPanel>
          <TabPanel className="tabPanel" value={value} index={0}>
            <div className="desc_box row_uniq">
              {isTableLoading ? (
                ""
              ) : (
                <div className="table_style_ui">
                  <GridTable
                    getRowId={(r) => r.id}
                    rows={ToolBoxTalkListAdminData?.saftyMeetingList ?? []}
                    rowCount={ToolBoxTalkListAdminData?.itemsCount ?? 0}
                    columns={columns}
                    loading={props?.showLoader}
                    page={page}
                    pagination
                    pageSize={pageSize}
                    rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                    onPageSizeChange={handlePageSizeChange}
                    onPageChange={handlePagignation}
                    backIconButtonProps={{ disabled: false }}
                    disableSelectionOnClick
                    components={{
                      LoadingOverlay: TableLoader,
                      NoRowsOverlay: NORow,
                    }}
                    onClick={handleOnCellClick}
                  />
                </div>
              )}
            </div>
          </TabPanel>
          <TabPanel className="tabPanel" value={value} index={2}>
            <div className="desc_box row_uniq">
              {isTableLoading ? (
                ""
              ) : (
                <div className="table_style_ui">
                  <GridTable
                    getRowId={(r) => r.safetyid}
                    rows={ToolBoxTalkListDataSchedule?.saftyMeetingList ?? []}
                    rowCount={ToolBoxTalkListDataSchedule?.itemsCount ?? 0}
                    columns={columnsCompleted}
                    //loading={isLoading}
                    loading={props?.showLoaderSchedule}
                    page={page}
                    pagination
                    pageSize={pageSize}
                    rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                    onPageSizeChange={handlePageSizeChange}
                    onPageChange={handlePagignation}
                    backIconButtonProps={{ disabled: false }}
                    disableSelectionOnClick
                    components={{
                      LoadingOverlay: TableLoader,
                      NoRowsOverlay: NORow,
                    }}
                    onClick={handleOnCellClickSchedule}
                  />
                </div>
              )}
            </div>
          </TabPanel>
        </Grid>
      </div>
    </>
  );
};

export default ToolBoxTalkAdminList;