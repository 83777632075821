import { Grid } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import Buttons from "../../../../library/custom/button/Button";
import { ORG_ID, ORG_NAME, USER_ID } from "../../../../constant/constant";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import { InputField } from "../../../../library/custom/textBox/InputField";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import DatePickers from "../../../../library/custom/datePicker/DatePicker";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import {
  ADMIN_ADD_STORM_WATERPROJECT,
  ADMIN_PROJECTS_LIST,
  ADMIN_RESOURCE,
  INSPECTION_AUTHORITY,
  SETUP_INSPECTION,
  SETUP_INSPECTION_LIST,
  SW_ADMIN_SAMPLINGLOG,
  SW_CONTACT_PROJECT,
  SW_INSPECTION_LIST,
  SW_OPEN_ITEMS,
  SW_PERMIT_LIST,
  SW_RAIN_FALL_DATA,
} from "../../../../constant/routeContant";
import ToolBar from "../../../../library/storm-water/toolBar";
import { dateValiadtor, getFormatedDate } from "../../../../utils/helper";
import { NumberField } from "../../../../library/custom/numberField/numberField";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import ViewImages from "../../../incidents/view-incident-report/common/viewImages/viewImages";
import FormDropZone from "../../../../library/dropZone/FormDropZone";

function AddPermit({
  projectid,
  editMode,
  onSubmit,
  permitId,
  permitDetails,
  getPermitType,
  stateId,
  Projectstate,
  projectNumber,
  projectName,
  clearAddPermit,
  customer,
  customerId,
  uploadContractorSignatureFile,
  fileUploadData,
  selectedClientSignature,
  setSelectedClientSignature,
  handleDeleteSignature,
}) {
  const customerName = customer ?? ORG_NAME();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  //  const deleteSignatureURL = setFiles("")

  const toolBarData = {
    title: "Project Tools",

    list: [
      {
        id: 10,
        title: "Edit Project",
        link: "",
      },
      {
        id: 1,
        title: "Project Contact",
        link: "",
        isshow: checkPrivileges([12, 132, 133, 134]),
      },
      {
        id: 2,
        title: "Inspection Service List",
        link: "",
        isshow: checkPrivileges([12, 99, 100, 101]),
      },
      {
        id: 9,
        title: "Setup Inspections",
        link: "",
        isshow: checkPrivileges([12, 99, 100]),
      },
      {
        id: 3,
        title: "Permits",
        link: "",
        isshow: checkPrivileges([12, 126, 127, 128]),
      },

      {
        id: 4,
        title: "Sampling Log",
        link: "",
        isshow: checkPrivileges([12, 129, 130, 131]),
      },
      {
        id: 5,
        title: "Open Items",
        link: "",
        isshow: checkPrivileges([12, 105, 106, 107]),
      },
      {
        id: 6,
        title: "Rainfall Data",
        link: "",
        isshow: true,
      },
      {
        id: 7,
        title: "Inspection Records",
        link: "",
        isshow: checkPrivileges([12, 102, 103, 104]),
      },

      {
        id: 8,
        title: "Inspection Authority",
        link: "",
        isshow: checkPrivileges([12, 96, 97, 98]),
      },
    ],
  };
  const initialState = {
    stateId: stateId,
    projectId: 0,
    permitteeTypeId: 0,
    holderName: "",
    permitNumber: "",
    approvalDate: null,
    expirationDate: null,
    isUsedExpirationDate: false,
    documentUrl: "string",
    createdBy: parseInt(USER_ID()),
  };

  const [approvalDateError, setApprovalDateError] = useState(false);
  const [expirationDateError, setExpirationDateError] = useState(false);
  const [permitTypeError, setPermitTypeError] = useState(false);
  const [isHolderName, setIsHolderName] = useState(false);
  const [isPermitNumber, setIsPermitNumber] = useState(false);
  const [formValue, setFormValue] = useState(initialState);
  const generalPermitExpDate = getPermitType?.genPermitExpDate;
  const [approvalDateErrMsg, setApprovalDateErrMsg] = useState(false);
  const [expireDateErrMsg, setExpireDateErrMsg] = useState(false);
  const [signUrl, setSignUrl] = useState(permitDetails?.signatureUrl);
  const hiddenContractorSignatureInput = useRef(null);

  const contractorsignatureUpload = (event) => {
    const fileUploaded = event.target.files[0];

    handleContractorSignatureUploadMethod(fileUploaded);
  };

  const handleContractorSignatureUploadMethod = (data) => {
    const formData = new FormData();
    formData.append("fileName", data);
    uploadContractorSignatureFile(formData);
  };

  function mappedData() {
    let data;
    if (editMode) {
      data = {
        stateId: stateId,
        projectId: projectid,
        permitteeTypeId: parseInt(formValue.permitteeTypeId),
        holderName: formValue.holderName,
        permitNumber: formValue.permitNumber,
        approvalDate:
          formValue.approvalDate === null
            ? null
            : getFormatedDate(formValue.approvalDate),
        expirationDate:
          formValue.expirationDate === null
            ? null
            : getFormatedDate(formValue.expirationDate),
        sitePermitExpirationDate:
          generalPermitExpDate === null ? null : getFormatedDate(generalPermitExpDate),
        isUsedExpirationDate: formValue.isUsedExpirationDate,
        documentUrl: formValue.fileUrls,
        permitId: permitId,
        modifiedBy: parseInt(USER_ID()),
        createdBy: parseInt(USER_ID()),
        signatureUrl: selectedClientSignature,
      };
    } else {
      data = {
        stateId: parseInt(formValue.stateId),
        projectId: projectid,
        permitteeTypeId: parseInt(formValue.permitteeTypeId),
        holderName: formValue.holderName,
        permitNumber: formValue.permitNumber,
        approvalDate: getFormatedDate(formValue.approvalDate),
        expirationDate: getFormatedDate(formValue.expirationDate),
        sitePermitExpirationDate:
          generalPermitExpDate === null ? null : getFormatedDate(generalPermitExpDate),
        isUsedExpirationDate: formValue.isUsedExpirationDate,
        documentUrl: formValue.fileUrls,
        createdBy: parseInt(USER_ID()),
        signatureUrl: selectedClientSignature,
      };
    }

    let errors = false;
    if (formValue.permitteeTypeId === 0) {
      setPermitTypeError(true);
      errors = true;
    }

    if (formValue?.holderName?.trim() === "") {
      setFormValue({ ...formValue, holderName: "" });
      setIsHolderName(true);
      errors = true;
    }
    if (formValue?.permitNumber.trim() === "") {
      setFormValue({ ...formValue, permitNumber: "" });
      setIsPermitNumber(true);
      errors = true;
    }
    if (formValue?.approvalDate === null) {
      setApprovalDateError(true);
      errors = true;
    }
    if (formValue?.approvalDate) {
      const validDate = dateValiadtor(getFormatedDate(formValue?.approvalDate));
      if (validDate === false) {
        setApprovalDateErrMsg(true);
        errors = true;
      } else if (validDate === "Valid date") {
        setApprovalDateErrMsg(false);
      }
    }
    if (formValue?.expirationDate === null) {
      setExpirationDateError(true);
      errors = true;
    }
    if (formValue?.expirationDate) {
      const validDate = dateValiadtor(
        getFormatedDate(formValue?.expirationDate)
      );
      if (validDate === false) {
        setExpireDateErrMsg(true);
        errors = true;
      } else if (validDate === "Valid date") {
        setExpireDateErrMsg(false);
      }
    }

    if (errors) {
      return false;
    }

    return data;
  }

  const history = useNavigate();

  const dataBack = {
    title: "Back to Project Permit",
    route: SW_PERMIT_LIST,
    projectName: projectName,
    projectNumber: projectNumber,
    projectid: projectid,
    customerName: customerName,
    customerId: customerId,
  };
  const submitData = () => {
    const data = mappedData();
    if (!data) {
    } else {
      editMode ? onSubmit(data) : onSubmit(data);
    }
  };

  const handleDeleteImageURL = () => {
    setFormValue({
      ...formValue,
      fileUrls: "",
    });
  };

  const handleDeleteSignature1 = () => {
    setFormValue({
      ...formValue,
      signatureUrl: "",
    });
  };

  const [resourceFilesData, setResourceFilesData] = useState("");
  useEffect(() => {
    formValue.fileUrls = resourceFilesData;
    setFormValue({ ...formValue });
  }, [resourceFilesData]);

  useEffect(() => {
    if (fileUploadData) {
      setSelectedClientSignature(fileUploadData?.files[0]?.filePath);
    }
  }, [fileUploadData]);

  const handleFileChange = (files, single) => {
    setResourceFilesData(files);
  };

  useEffect(() => {
    if (permitDetails) {
      setFormValue({
        stateId: permitDetails?.stateId,
        projectId: permitDetails?.projectId,
        permitteeTypeId: permitDetails?.permitteeTypeId,
        holderName: permitDetails?.holderName,
        permitNumber: permitDetails?.permitNumber,
        approvalDate: getFormatedDate(permitDetails?.approvalDate),
        expirationDate: getFormatedDate(permitDetails?.expirationDate),
        sitePermitExpirationDate: getFormatedDate(permitDetails?.sitePermitExpirationDate),
        checkBox: permitDetails?.isUsedExpirationDate,
        fileUrls: permitDetails?.documentUrl,
        createdBy: permitDetails?.createdBy,
      });
      setSelectedClientSignature(permitDetails?.signatureUrl);
    }
  }, [permitDetails]);

  const primaryPermitee = getPermitType?.permitType.filter((val) => {
    return val?.value === "Primary" || val?.value === "primary";
  });

  return (
    <>
      <section className="grid_main_sec sw">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">
            {editMode ? "Edit Project Permit" : "Add Project Permit"}
          </div>
        </div>

        <Grid container>
          <Grid lg={8} md={12} xs={12} pr={2}>
            {editMode ? (
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <InputField
                    isRequired={true}
                    fullWidth={true}
                    disabled={true}
                    type="text"
                    label="Permit ID"
                    value={permitId}
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        equipmentID: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </Grid>
            ) : (
              ""
            )}
            <Grid container spacing={2} mt={2}>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <FormLabels label="Permittee Type" isRequired={true} />
                  <SelectMenu
                    listData={getPermitType?.permitType}
                    value={formValue.permitteeTypeId}
                    placeholder="Please Select"
                    onchangehandler={(e) => {
                      const selectedValue = String(e.target.value); // Convert to string
                      setFormValue({
                        ...formValue,
                        permitteeTypeId: selectedValue,
                      });
                    }}
                    isError={permitTypeError}
                    errorMsg={permitTypeError ? "PermitType is Required" : ""}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid mt={1} item container spacing={2}>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <InputField
                    isRequired={true}
                    type="text"
                    label="Permit Holder"
                    placeholder={"Add Permit Holder..."}
                    value={formValue?.holderName}
                    onChange={(e) => {
                      if (
                        e.target.value === " " &&
                        formValue.holderName.trim() === ""
                      )
                        setFormValue({
                          ...formValue,
                          holderName: "",
                        });
                      else
                        setFormValue({
                          ...formValue,
                          holderName: e.target.value,
                        });
                    }}
                    isError={isHolderName}
                    errorMsg={isHolderName ? "Holder Name is Required" : ""}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <InputField
                    isRequired={true}
                    type="text"
                    label={"Permit Number"}
                    value={formValue.permitNumber}
                    placeholder={"Add Permit Number..."}
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        permitNumber: e.target.value,
                      })
                    }
                    isError={isPermitNumber}
                    errorMsg={
                      isPermitNumber ? "Permit Number  is required" : ""
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container mt={1} spacing={2}>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <FormLabels label={"Approval Date"} isRequired={true} />
                  <DatePickers
                    maxDateEnable={true}
                    value={formValue.approvalDate}
                    onChangeHandler={(e) => {
                      setFormValue({
                        ...formValue,
                        approvalDate: e,
                      });
                      setApprovalDateError(false);
                      setApprovalDateErrMsg(false);
                    }}
                    isError={approvalDateError}
                    errorMsg={approvalDateError ? "Field is Required" : ""}
                  />
                </FormControl>
                {approvalDateErrMsg && formValue.approvalDate != null && (
                  <div className="errorMsg">Invalid Date</div>
                )}
              </Grid>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <FormLabels
                    label={"General Permit Expiration Date"}
                    isRequired={false}
                  />
                  <DatePickers
                    maxDateEnable={true}
                    value={generalPermitExpDate}
                    disabled={true}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container mt={1} spacing={2}>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <FormLabels
                    label={"Project Permit Expiration Date"}
                    isRequired={true}
                  />
                  <DatePickers
                    maxDateEnable={true}
                    value={formValue.expirationDate}
                    onChangeHandler={(e) => {
                      setFormValue({
                        ...formValue,
                        expirationDate: e,
                      });
                      setExpirationDateError(false);
                      setExpireDateErrMsg(false);
                    }}
                    isError={expirationDateError}
                    errorMsg={expirationDateError ? "Field is Required" : ""}
                  />
                </FormControl>
                {expireDateErrMsg && formValue.expirationDate != null && (
                  <div className="errorMsg">Invalid Date</div>
                )}
              </Grid>
            </Grid>

            <Grid container mt={5}>
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <FormDropZone
                  label={"Add Permit"}
                  paraText1={"Drop the files here ..."}
                  paraText2={"Drop document/image here, "}
                  paraText3={"or Browse"}
                  handleFileChange={handleFileChange}
                  fileType={10}
                  imageUrl={formValue.fileUrls}
                  deleteImageURL={(url) => handleDeleteImageURL(url)}
                  id="addResources"
                  key="addResources"
                  isMuliple={false}
                  isDoc={true}
                  isImage={false}
                  allFiles={true}
                  isRequired={true}
                />
              </Grid>
            </Grid>
            {/* {stateId === 3 &&
              parseInt(primaryPermitee?.[0]?.key) ===
                parseInt(formValue.permitteeTypeId) && (
                <Grid item lg={6} xs={12} sm={12} md={6} mt={4}>
                  <input
                    type="file"
                    ref={hiddenContractorSignatureInput}
                    onChange={(event) => {
                      contractorsignatureUpload(event);
                    }}
                    style={{ display: "none" }}
                    accept="image/*"
                    key={selectedClientSignature ? selectedClientSignature.name : 'default'}
                  />
                  <label
                    className="signatureBtn"
                    onClick={() => {
                      hiddenContractorSignatureInput.current.click();
                    }}
                    htmlFor="actual-btn"
                  >
                    Upload Signature
                  </label>
                  <div className="imgBox1">
                    {selectedClientSignature ? (
                      <>
                        <div
                          className="removeIcon"
                          onClick={() => handleDeleteSignature()}
                        >
                          X
                        </div>
                        <img
                          className="borderSignatureImg newPermitSignature "
                          src={selectedClientSignature}
                          alt=""
                        />
                      </>
                    ) : editMode && formValue.signatureUrl ? (
                      <>
                        <div
                          className="removeIcon"
                          onClick={() => handleDeleteSignature1()}
                        >
                          X
                        </div>
                        <div className="signatureImg">
                          <img
                            className="borderSignatureImg newPermitSignature"
                            src={formValue.signatureUrl}
                            alt=""
                          />
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Grid>
              )} */}

            <Grid container item mt={3}>
              <Grid item lg={6} md={12} xs={12}>
                <div className="">
                  <BackBtn dataBack={dataBack} />
                </div>
              </Grid>
              <Grid item lg={6} xs={12} md={12} mt={2.2}>
                <Grid container item lg={12} xs={12} className="divisionBtnBox">
                  <Grid lg={6} xs={12} sm={6} mt={0.5}>
                    <Buttons
                      varientText={true}
                      label="Cancel"
                      onClick={() => history(-1)}
                    />
                  </Grid>

                  <Grid lg={6} xs={12} sm={6} md={4}>
                    <Buttons
                      varientContained={true}
                      label={editMode ? "Update" : "Save"}
                      onClick={submitData}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid lg={4} md={12} xs={12} pl={1} className="permitsTootBar">
            <div className="toolBarBox">
              <div className="otherDetail">
                <h3>Projects Details</h3>
                <h2>{projectName}</h2>
                <h2>{projectNumber}</h2>
              </div>

              <div className="otherDetail">
                <h3>Client Name</h3>
                <h2>{customerName}</h2>
              </div>
              <ToolBar
                toolBarData={toolBarData}
                handleClick={(e) => {
                  if (e.id === 1) {
                    navigate(SW_CONTACT_PROJECT, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectid,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 2) {
                    navigate(SETUP_INSPECTION_LIST, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectid,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 9) {
                    navigate(SETUP_INSPECTION, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectid,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                        rightSideMode: true,
                      },
                    });
                  }
                  if (e.id === 3) {
                    navigate(SW_PERMIT_LIST, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectid: projectid,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 4) {
                    navigate(SW_ADMIN_SAMPLINGLOG, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectid,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 5) {
                    navigate(SW_OPEN_ITEMS, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectid,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 6) {
                    navigate(SW_RAIN_FALL_DATA, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectid,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 7) {
                    navigate(SW_INSPECTION_LIST, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectid,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 8) {
                    navigate(INSPECTION_AUTHORITY);
                  }
                  if (e.id === 10) {
                    navigate(ADMIN_ADD_STORM_WATERPROJECT, {
                      state: {
                        editMode: true,
                        projectid: projectid,
                        projectName: projectName,
                        projectNumber: projectNumber,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 12) {
                    navigate(ADMIN_RESOURCE, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectid,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  } else return;
                }}
              />
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}

export default AddPermit;
