import React, { useState } from "react";
import Buttons from "../../../../library/custom/button/Button";
import { Grid } from "@mui/material";
import {
  CLIENT_SATATUS_FILTER,
  ISwitch,
  PAGE_SIZE,
  USER_ID,
} from "../../../../constant/constant";
import Loader, { TableLoader } from "../../../../library/common/Loader";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import NORow from "../../../../library/custom/noRow/noRow";
import ReportRowManipulte from "./jhaReportRowManipulate";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import { InputField } from "../../../../library/custom/textBox/InputField";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import * as jhaAction from "../../../../redux/actions/jhaAction";
import { useDispatch } from "react-redux";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import ServiceeFilterList from "../jha/serviceListFilter";
import FilterListIcon from "@mui/icons-material/FilterList";
import { InactiveClient } from "../../../../utils/helper";

const JhaActivities = ({
  handleEditOnClick,
  isLoading,
  categoriesList,
  addJhaActivity,
  getJhaActivityList,
  formValue,
  setFormValue,
  activityList,
  handleStatusChange,
  itemsCount,
}) => {
  const dataBack = {
    title: "Back to Hazard Analysis Configuration",
    route: -1,
  };

  const [anchorElFilter, setAnchorElFilter] = useState(null);

  const openFilter = Boolean(anchorElFilter);
  const id = openFilter ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const [filterFormValue, setFilterFormValue] = useState({
    isActive: "",
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleCloseRerportRow = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const [page, setpage] = useState(1);

  const handleChange = (activitySetItem) => {
    if (InactiveClient()) return;
    const newCatList = [...activityList];
    newCatList.forEach((lists) => {
      if (lists.id === activitySetItem.id) {
        lists.isGlobal = false;
        lists.activityId = lists.id;
        lists.isActive = !lists.isActive;
        lists.listsmodifiedBy = parseInt(USER_ID());
      }
    });
    setpage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 300);
    handleStatusChange(activitySetItem);
  };
  const columns = [
    { field: "activityName", headerName: "Activities", flex: 3 },
    { field: "modifiedDate", headerName: "Last Updated", flex: 1 },
    {
      flex: 1,
      field: "status",
      headerName: "Status",
      renderCell: (rows) => (
        <Grid className="catListBox">
          <Grid className="closeIcon">
            <FormControl component="fieldset" variant="standard">
              <FormGroup className="isActiveBox">
                {checkPrivileges([58, 12]) && (
                  <FormControlLabel
                    control={
                      <ISwitch
                        checked={
                          rows?.row.isActive ? rows?.row.isActive : false
                        }
                        onChange={() => handleChange(rows?.row)}
                        name="status"
                      />
                    }
                  />
                )}
                <div>{rows?.row?.isActive ? "Active" : "Inactive"}</div>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      ),
    },
    {
      flex: 0.5,
      field: "icon",
      renderHeader: () => "",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            editOnClick={() => {
              setOpen(true);
              seteditFormValue({
                ...editFormValue,
                activityId: rows?.row?.id,
                activityName: rows?.row?.activityName,
                isActive: rows?.row?.isActive,
              });
            }}
            edit={checkPrivileges([58, 59, 12])}
            editLabel="Edit Activity Name"
            handleClose={handleCloseRerportRow}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
          />
        );
      },
    },
  ];
  const [open, setOpen] = React.useState(false);

  const [isAlreadyExists, setIsAlreadyExists] = useState(false);
  const [isEditAlreadyExists, setIsEditAlreadyExists] = useState(false);
  const [isExistsErrorMsg, setisExistsErrorMsg] = useState("");
  const [isEditExistsErrorMsg, setisEditExistsErrorMsg] = useState("");
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const [isCatId, setisCatId] = useState(false);
  const [isActivityName, setisActivityName] = useState(false);
  const [isEditActivityName, setisEditActivityName] = useState(false);
  const [activityNameErrMag, setactivityNameErrMag] = useState("");

  // add

  function submitData() {
    if (InactiveClient()) return;
    let error = false;
    if (formValue.categoryId === "") {
      setisCatId(true);
      error = true;
    }
    if (formValue.activityName.trim() === "") {
      setisActivityName(true);
      setactivityNameErrMag("Activity Name is Required");
      setFormValue({
        ...formValue,
        activityName: "",
      });
      error = true;
    }
    const index = activityList?.findIndex(
      (object) =>
        object.activityName.toUpperCase() ===
        formValue?.activityName.toUpperCase()
    );
    if (index >= 0) {
      setIsAlreadyExists(true);
      setisExistsErrorMsg("Activity Already Exists");
      error = true;
      return;
    }
    if (!error) {
      addJhaActivity(formValue);
      setisActivityName(false);
      setFormValue({
        ...formValue,
        activityName: "",
        // categoryId: "",
      });
    }
  }

  // pagination

  const handlePagignation = (newPage) => {
    setpage(newPage + 1);
    const data = {
      categoryId: formValue.categoryId,
      search: "",
      page: newPage + 1,
      pageSize: PAGE_SIZE,
      isAll:
        filterFormValue.isActive === 1
          ? 0
          : filterFormValue.isActive === 2
            ? 2
            : 1,
    };
    getJhaActivityList(data);
  };
  // edit
  const [editFormValue, seteditFormValue] = useState({
    categoryId: 0,
    activityName: "",
    isGlobal: false,
    activityId: 0,
    isActive: undefined,
    modifiedBy: parseInt(USER_ID()),
  });

  const [isTableLoading, setisTableLoading] = useState(false);
  console.log("Deployed")
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid className="modalContainer">
          <Box className="modalBox">
            <div className="modalTitle">Edit Activity Name</div>
            <div className="modalInputBox">
              <InputField
                type="text"
                isRequired={true}
                value={editFormValue.activityName}
                onChange={(e) => {
                  seteditFormValue({
                    ...editFormValue,
                    activityName: e.target.value,
                  });
                  setisEditActivityName(false);
                  setactivityNameErrMag("");
                  setisEditExistsErrorMsg("");
                }}
                label="Edit Activity Name"
                placeholder="Activity Name Here..."
                isError={isEditActivityName}
                errorMsg={isEditActivityName && activityNameErrMag}
              />
            </div>
            <Grid container className="modalFooterBox">
              <Grid item lg={2} xs={12} sm={3} md={3}>
                <Buttons
                  varientText={true}
                  label="Cancel"
                  onClick={handleClose}
                />
              </Grid>
              <Grid item lg={2} xs={12} sm={3} md={3}>
                <Buttons
                  varientContained={true}
                  label={"Save"}
                  onClick={() => {
                    let error = false;
                    if (editFormValue.activityName.trim() === "") {
                      seteditFormValue({
                        ...editFormValue,
                        activityName: "",
                      });
                      setisEditActivityName(true);
                      setactivityNameErrMag("Activity Name is Required");
                      error = true;
                    }

                    const index = activityList?.findIndex(
                      (object) =>
                        object.activityName.toUpperCase() ===
                        editFormValue?.activityName.toUpperCase()
                    );
                    if (index >= 0) {
                      setIsEditAlreadyExists(true);
                      setisEditExistsErrorMsg("Activity Already Exists");
                      error = true;
                      return;
                    }
                    if (!error) {
                      handleEditOnClick(editFormValue);
                      handleClose();
                    }
                  }}
                />
              </Grid>

              <Grid md={12} item>
                {isEditAlreadyExists && (
                  <p className="errorMsg">{isEditExistsErrorMsg}</p>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Modal>

      <Grid className="inspectionOverRide">
        <Grid container className="confBox">
          <Grid item md={8.5} xs={12}>
            <div className="confTitile">Activities</div>
          </Grid>
        </Grid>
        <Grid item container mt={1.5} lg={12}>
          <Grid mt={3} lg={6} xs={12} sm={12} md={6} item>
            <FormControl fullWidth>
              <FormLabels label="Select Category" isRequired={true} />
              <SelectMenu
                listData={categoriesList}
                value={formValue.categoryId}
                placeholder="Select Category"
                onchangehandler={(e) => {
                  setFormValue({
                    ...formValue,
                    categoryId: e.target.value,
                  });
                  seteditFormValue({
                    ...editFormValue,
                    categoryId: e.target.value,
                  });
                  getJhaActivityList({
                    categoryId: e.target.value,
                    search: "",
                    page: page,
                    pageSize: PAGE_SIZE,
                    isAll:
                      filterFormValue.isActive === 1
                        ? 0
                        : filterFormValue.isActive === 2
                          ? 2
                          : 1,
                  });
                }}
                isError={isCatId}
                errorMsg={isCatId ? "Category is Required" : ""}
              />
            </FormControl>
          </Grid>
        </Grid>
        {checkPrivileges([58, 59, 12]) && (
          <Grid item container mt={1.5} lg={12} spacing={1}>
            <Grid item lg={8} mt={2} className="Catoption">
              <InputField
                isRequired={true}
                disabled={false}
                type="text"
                label={"Activities"}
                value={formValue.activityName}
                placeholder="Enter here"
                onChange={(e) => {
                  setisExistsErrorMsg("");
                  setactivityNameErrMag("");
                  setisActivityName(false);
                  if (
                    e.target.value === " " &&
                    formValue.activityName.trim() === ""
                  )
                    setFormValue({
                      ...formValue,
                      activityName: "",
                      isActivityName: false,
                    });
                  else
                    setFormValue({
                      ...formValue,
                      activityName: e.target.value,
                      isActivityName: false,
                    });
                }}
                isError={isActivityName}
                errorMsg={isActivityName ? "Activity Name is Required" : ""}
              />
            </Grid>
            <Grid className="smallAddBtn" item mt={6} lg={1.1}>
              <Buttons
                aria-describedby="dd"
                variant="contained"
                type="button"
                name="btn"
                label={"Create"}
                onClick={(e) => submitData()}
                id="btnAddEquipment"
                varientAddIconBlue={true}
              ></Buttons>
            </Grid>
            <Grid md={12} item>
              {isAlreadyExists && (
                <p className="errorMsg">{isExistsErrorMsg}</p>
              )}
            </Grid>
          </Grid>
        )}

        <Grid item container md={12} className="CategoriesBox" mt={3}>
          <Grid item md={12} sm={12} xs={8}>
            <div className="filter_of_caselist filter_of_caselist_right">
              <div className="filter_btn">
                <button
                  aria-describedby={id}
                  variant="contained"
                  type="button"
                  name="btn"
                  onClick={(e) => handleClick(e)}
                >
                  <FilterListIcon id="icn" /> Filters
                </button>
              </div>
            </div>

            <ServiceeFilterList
              id={id}
              open={openFilter}
              anchorEl={anchorElFilter}
              handleClose={() => {
                setAnchorElFilter(null);
                window.close();
              }}
              handleApply={() => {
                setpage(1);
                const data = {
                  categoryId: formValue.categoryId,
                  search: "",
                  page: 1,
                  pageSize: PAGE_SIZE,
                  isAll:
                    filterFormValue.isActive === 1
                      ? 0
                      : filterFormValue.isActive === 2
                        ? 2
                        : 1,
                };
                setisTableLoading(true);
                setTimeout(() => {
                  setisTableLoading(false);
                }, 300);
                getJhaActivityList(data);
                setAnchorElFilter(null);
                window.close();
              }}
              secondLabel="Status"
              secondLebelFilter={CLIENT_SATATUS_FILTER}
              secondLebelValue={filterFormValue.isActive}
              firstLebelOnChanges={(e) =>
                setFilterFormValue({
                  ...filterFormValue,
                })
              }
              secondLebelOnChanges={(e) =>
                setFilterFormValue({
                  ...filterFormValue,
                  isActive: e.target.value,
                })
              }
              handleClear={() => {
                setpage(1);
                setFilterFormValue({
                  assetType: "",
                  isActive: "",
                });
                const data = {
                  categoryId: formValue.categoryId,
                  search: "",
                  page: page,
                  pageSize: PAGE_SIZE,
                  isAll: 1,
                };

                setisTableLoading(true);
                setTimeout(() => {
                  setisTableLoading(false);
                }, 300);
                getJhaActivityList(data);
                setAnchorElFilter(null);
                window.close();
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid container item md={12} xs={12}>
            <Grid item md={12} sm={12} className="" mt={3}>
              {isTableLoading ? (
                <Loader />
              ) : (
                <div className="desc_box row_uniq">
                  <div className="table_style_ui">
                    <GridTable
                      getRowId={(r) => r.id}
                      rows={activityList ?? []}
                      rowCount={itemsCount ?? 0}
                      columns={columns}
                      loading={isLoading}
                      checkboxSelection={false}
                      page={1}
                      pagination={false}
                      pageSize={PAGE_SIZE}
                      rowsPerPageOptions={[PAGE_SIZE]}
                      backIconButtonProps={{ disabled: false }}
                      disableSelectionOnClick
                      components={{
                        LoadingOverlay: TableLoader,
                        NoRowsOverlay: NORow,
                      }}
                      onPageChange={handlePagignation}
                    />
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
        <div onClick={() => dispatch(jhaAction.clearJha())}>
          <BackBtn dataBack={dataBack} />
        </div>
      </Grid>
    </>
  );
};

export default JhaActivities;
