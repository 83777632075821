import { combineReducers } from "redux";
import { creatCaseReducer, updateCaseReducer } from "./caseReducer";
import {
  incidentListReducer,
  incidentReducer,
  deleteIncidentsReducer,
  actionListReducer,
  closeActionItemReducer,
  casesListReducer,
  viewCaseReducer,
  closeIncidentReducer,
  deleteCaseReducer,
  getIncidentReportReducer,
  getIncidentReportInjuredBodyPartReducer,
  getIncidentReportInjuredBodyPartListReducer,
  getIncidentReportMostCommonInjuryReducer,
  getIncidentReportMostCommonInjuryListReducer,
  getIncReportMostCommonDayOfInjuryReducer,
  getIncidentReportUserWithMostIncidentReducer,
  getIncidentReportUserWithMostIncidentListReducer,
  getIncidentReportUserWithMostActionItemsReducer,
  getIncidentReportUserWithMostActionItemsListReducer,
  projectwithmostincidentsReducer,
  projectwithmostincidentsListReducer,
  vehiclewithmostincidentsReducer,
  vehiclewithmostincidentsListReducer,
  getOSHAEstablishmentListReducer,
  addEstablishmentReducer,
  updateEstablishmentReducer,
  getEstablishmentDetailReducer,
  updateEstablishmentStatusReducer,
  getOshaLogReducer,
  getOshaDetailsReducer,
  addOshaDetailsReducer,
  getListAddedInOSHAReducer,
  getSubmitDetailsToOshaReducer,
  addGetOSHALogReducer,
} from "./incidentReducer";
import {
  getLoginOTPReducer,
  getLoginOTPUserVerifyReducer,
  loginReducer,
  notifiactionCountReducer,
  NotifiactionListReducer,
  NotifiactionLoginReducer,
  NotifiactionLogoutReducer,
  updateNotifiactionCountReducer,
  updateNotifiactionListReducer,
} from "./loginReducer";
import {
  getNearMissesReducer,
  nearMissesReducer,
  updateNearMissesReducer,
} from "./nearMissesReducer";
import {
  perosnalInjuryReducer,
  updatePersonalInjuryReducer,
  viewPersonalInjuryReducer,
} from "./perosnalInjuryReducer";
import {
  projectListReducer,
  createProjectReducer,
  deleteProjectReducer,
  updateProjectReducer,
  getProjectDetailsReducer,
  procoreProjectsListReducer,
  syncProcoreProjectsListReducer,
} from "./projectReducer";
import {
  onboardingReducer,
  otpSignupReducer,
  signupReducer,
  updateOnboardingReducer,
  verifyMobileReducer,
} from "./signupReducer";
import { cityReducer, stateReducer } from "./stateCityReducer";
import {
  getStolenPropertyReducer,
  stolenPropertyReducer,
  updateStolenPropertyReducer,
} from "./stolenPropertyReducer";
import { createUploadFilesReducer } from "./uploadFilesReducer";
import {
  searchUserReducer,
  userReducer,
  userGroupReducer,
  addUserGroupReducer,
  userReducerList,
  getUserListDetsilsReducer,
  updateUserListReducer,
  deleteUserListReducer,
  addUserListReducer,
  searchAllUserReducer,
  deleteUserGroupReducer,
  updateUserGroupReducer,
  getUserGroupDetsilsReducer,
  leaveGroupReducer,
  searchAllGroupReducer,
  userGroupListReducer,
  userConfigurationReducerList,
  updateUserConfigurationListReducer,
  getUserConfigurationDetsilsReducer,
  updateUserOnboardReducer,
  updateUserOnboardStatusReducer,
  NonPrivilegeuserListReducer,
  deleteNonPrivilegeUserListReducer,
  addNonPrivilegeUserListReducer,
  getNonPrivilegeUserListDetsilsReducer,
  updateNonPrivilegeUserListReducer,
  convertNonPrivilegeUserListReducer,
  recreateUsersReducer,
  getAssetOnUserReducer,
  getSupervisorOnAssetReducer,
  checkUserInfoReducer,
  userByRoleListReducer,
  userSyncWithProcoreReducer,
  bouncedEmailReducerList,
} from "./userReducer";
import {
  utilityDamageReducer,
  viewUtilityDamageReducer,
  updateUtilityDamageReducer,
} from "./utilityDamageReducer";
import {
  vehicleAccidentReducer,
  viewVehicleAccidentReducer,
  updateVehicleAccidentReducer,
  getRegNumberReducer,
} from "./vehicleAccidentReducer";
import {
  inspectionReducer,
  inspectionViewReducer,
  inspectionDetailView,
  inspectionDeleteReducer,
  adminAddUpdateSubcategoryReducer,
  adminUpdateInspectionReducer,
  addUpdateQuestionsReducer,
  adminAddUpdateInspectionReducer,
  deleteQuestionReducer,
  getCategoriesListReducer,
  getSubCategoriesListReducer,
  getQuestionsListReducer,
  getQuestionSetListReducer,
  cloneQuestionSetReducer,
  adminAddUpdateCategoryReducer,
  getAssetListReducer,
  qestSetDetailReducer,
  closeInspectionOpenItemReducer,
  getInspectionOpenItemDetailReducer,
  getInspectionViolationCountsReducer,
  getItemListReducer,
  updateReprimandToClose,
  getInspectionAcknowledgementReducer,
  updateInspectionAcknowledgementReducer,
  getExportItemListReducer,
  inspectionFieldsMapReducer,
  updateInspectionFieldsMapReducer,
  inspectionReportAssetDetailsReducer,
  getInspecionReportReducer,
  getOpenItemPhotosReducer,
  getInspectionOpenItemCountReducer,
} from "./inspectionReducer";
import {
  reprimandFormDataReducer,
  reprimandViewReducer,
  reprimandCountReducer,
} from "./reprimandReducer";
import {
  createCredentialReducer,
  getCredentialListReducer,
  getCredentialDetsilsReducer,
  updateCredentialReducer,
  createUserCredentialReducer,
  getSearchCredentialReducer,
  assignCredentialReducer,
  getCredentialUserListReducer,
  getCredentialListByUsersReducer,
  getCredentialListByCredentialsReducer,
  getCredentialViewDetsilsReducer,
  updateUserCredentialReducer,
  createAdminCredentialReducer,
  deleteCredentialReducer,
} from "./credentialReducer";

import {
  articleCatSubcategoryReducer,
  articleReducer,
  articlesBasedCatSubcategoryReducer,
  articlesDetailsReducer,
  createTicketReducer,
  deleteFAQReducer,
  getDashboardTicketsReducer,
  getTicketCategoryReducer,
  getTicketDetailsReducer,
  getTicketsAdminDeskReducer,
  getTicketsFeedbackDeskReducer,
  getTicketsReducer,
  postAddFAQReducer,
  postClosedTicketReducer,
  postUpdateSeverityTicketReducer,
  postUserAssignTicketReducer,
  postUserFeedbackReducer,
  replyTicketReducer,
  updateFAQReducer,
} from "./supportReducer";
import {
  getCoursesListReducer,
  getCategoryListReducer,
  createCoursesReducer,
  updateCourseReducer,
  getlessonsDropDownListReducer,
  deleteCourseReducer,
  publishCourseReducer,
  getAssignCourseUserList,
  assignCourseReducer,
  unAssignCourseReducer,
  getCompletedCourseStatuscheckReducer,
  unEnrollCourseReducer,
  searchCoursesReducer,
  MappedGlobalCoursesListReducer,
  specifyCoursetoUserViewClientDataReducer,
  specifyCoursetoUserViewCourseDataReducer,
} from "./coursesReducer";
import {
  getLessonsListReducer,
  createLessonReducer,
  updateLessonReducer,
  deleteLessonReducer,
  publishLessonReducer,
  assignLessonReducer,
  getAssignLessonUserListReducer,
  completeLessonReducer,
  getAssignAlertUserListReducer,
  getCompletedLessonStatuscheckReducer,
  unAssignLessonReducer,
  unEnrollLessonReducer,
  searchLessonsReducer,
  MappedGlobalLessonListReducer,
  specifyLessontoUserViewClientDataReducer,
  specifyLessontoUserViewLessonDataReducer,
} from "./lessonsReducer";

import {
  getUserLessonsListReducer,
  getUserLessonViewReducer,
  startLessonReducer,
} from "./lessonsUserReducer";
import {
  getUserCoursesListReducer,
  getUserCourseViewReducer,
  startCourseReducer,
} from "./coursesUserReducer";
import {
  contactListReducer,
  createContactReducer,
  deleteContactsReducer,
  viewContactsReducer,
  updateContactReducer,
  convertNonPrivilegeContactListReducer,
} from "./contactReducer";
import {
  resourceListReducer,
  resourceDetailsReducer,
  createResourceReducer,
  updateResourceReducer,
  deleteResourceReducer,
} from "./resourceReducer";

import {
  observationReducer,
  observationDetailsReducer,
  updateObservationReducer,
  openItemReducer,
  ListOfObservationReducer,
} from "./observationReducer";
import {
  createQuizReducer,
  getQuizListReducer,
  submitQuizReducer,
  getQuizDetailReducer,
  updateQuizReducer,
} from "./quizReducer";
import {
  assetListReducer,
  deleteAssetReducer,
  getAssetDetailsReducer,
  postAddAssestReducer,
  updateAssetReducer,
  getAssetDynomoListReducer,
  getUserAssetListReducer,
} from "./assetsReducer";
import {
  deleteVehicleReducer,
  getVehicleDetailsReducer,
  postAddVehicleReducer,
  updateVehicleReducer,
  vehicleListReducer,
} from "./vehicleReducer";
import {
  equipmentListReducer,
  createEquipmentReducer,
  deleteEquipmentReducer,
  equipmentDetailReducer,
  updateEquipmentsReducer,
} from "./equipmentsReducer";
import {
  getDropdownDataKeyValueReducer,
  getInspectionsReducer,
  getSupervisorByProjectReducer,
} from "./adminCommonReducer";
import {
  createDivisionsReducer,
  getDivisionsListReducer,
  deleteDivisionsReducer,
  viewDivisionsReducer,
  updateDivisionsReducer,
} from "./divisionsReducer";
import {
  getRoleListReducer,
  creatRoleReducer,
  creatAssignRoleReducer,
  getRoleListDetsilsReducer,
  updateRoleListReducer,
  deleteRoleReducer,
  getUserInRoleList,
} from "./roleReducer";
import { getAssignListReducer } from "./assignReducer";

import {
  getKudosLogReducer,
  getReprimandLogReducer,
  getViolationLogReducer,
} from "./safetyLogReducer";
import {
  getClientListReducer,
  createClientReducer,
  updateClientReducer,
  getClientDetsilsReducer,
  deleteClientReducer,
  searchAllClientsReducer,
  getSyncWithProcoreReducer,
  getProcoreCompanyReducer,
} from "./clientReducer";

import {
  createContractorReducer,
  getContractorListReducer,
  deleteContractorReducer,
  viewContractorReducer,
  updateContractorReducer,
  createPersonalContractorReducer,
  updatePersonalContractorReducer,
  getPersonalContractorListReducer,
  deletePersonalContractorReducer,
  viewPersonalContractorReducer,
  getOnboardedContractorListReducer,
  updateOnboardedContractorListReducer,
  getContractorConfigurationDetsilsReducer,
  updateContractorOnboardReducer,
  updateContractorOnboardStatusReducer,
  searchSubcontractorReducer,
  createContractorClientWiseReducer,
} from "./contractorReducer";
import {
  createCategoryReducer,
  categoryListReducer,
  activeCategoryReducer,
  createSupportCategoryReducer,
  categorySupportListReducer,
  activeSupportCategoryReducer,
  categoryUtilityIncidentListReducer,
  createUtilityIncidentCategoryReducer,
  activeIncidentCategoryReducer,
  updateCategoryConfigationReducer,
  updateSupportCategoryConfigationReducer,
  updateIncidentCategoryConfigationReducer,
} from "./utilityCategoryReducer";
//reports
import {
  getConfiguredCredentialReportReducer,
  getCredentialReportReducer,
  getLookupCredentialReportReducer,
} from "./reportReducer";
import {
  createToolBoxTalkReducer,
  getToolBoxTalkViewReducer,
  updateToolBoxTalkReducer,
  deleteToolBoxTalkReducer,
  getToolSafetyMeetingSearchReducer,
  getToolBoxTalkListReducer,
  getAdminToolBoxTalkListReducer,
  getProjectSearchListReducer,
  searchAllUserProjectWiseReducer,
  getAssignTookBoxUserList,
  postSafetyMeetingAlertReducer,
  getMySafetyMeetingListReducer,
  getScheduleInstructorDetailsReducer,
  getScheduleAttendeesDetailsReducer,
  getScheduleSaftyMeetingDetailsReducer,
  updateAdminAssignInstructorsReducer,
} from "./toolBoxTalkReducer";
import {
  assignAdminToInstructorToolReducer,
  getScheduleMeetingDetailReducer,
  assignInstructorToUserToolReducer,
  assignSelfInstructorToUserToolReducer,
  verifyAttendeeReducer,
  getVerifyAttendeeListReducer,
  deleteScheduleToolBoxTalkReducer,
  userMarkedCompletedToolBoxReducer,
  getExtraUserListReducer,
  createExtraUserReducer,
} from "./toolBoxTalkAssignReducer";

import {
  updateUserDashboardCartReducer,
  getDashboardCartListReducer,
  getUserDashboardCartListReducer,
} from "./dashboardReducer";

import {
  getLmsDashboardReportDetailsReducer,
  getLmsDashboardReportReducer,
  getLmsReportCourseReducer,
  getLmsReportLessonReducer,
  getLmsReportReducer,
} from "./lmsReportReducer";
import {
  getPersonalInjuryIncidentReportCountReducer,
  getPersonalInjuryIncidentReportListReducer,
} from "./personalInjuryIncidentReportReducer";
import {
  getSiteDetsilsReducer,
  siteListReducer,
  siteReducer,
  updateSiteReducer,
  siteListAutoSearchReducer,
  getSiteReducer,
} from "../storm-water/reducers/siteReducer";
import {
  createSwProjectReducer,
  swprojectListReducer,
  getSwProjectDetailReducer,
  updateSwProjectReducer,
  postAddContactInProjectReducer,
  getContactInProjectListReducer,
  updateProjectContactStatusReducer,
  editOneContactInProjectReducer,
  deleteContactInProjectReducer,
  swrainFallDataReducer,
  deleteSWProjectReducer,
} from "../storm-water/reducers/projectReducer";
import {
  createSwProjectSampleReducer,
  swprojectSampleListReducer,
} from "../storm-water/reducers/swSampleLogReducer";

import {
  addPermitReducer,
  permitDetailsReducer,
  getpermitListReducer,
  updatePermitReducer,
  addPermitTypeReducer,
  getPermitTypeListReducer,
  updatePermitTypeReducer,
  getGeneralPermitTypeListReducer,
  updateGeneralPermitReducer,
  getPermitTypeReducer,
} from "../storm-water/reducers/permitReducer";
import {
  swQuestionSetListReducer,
  createSwQuestionSetReducer,
  getSwQuestionSetDetailReducer,
  updateSwQuestionSetStatusReducer,
  createSwQuestionSetProjectMapReducer,
  getSwQuestionSetProjectMapDetailReducer,
  getSwQuestionSetProjectMapListReducer,
  createSwCategoryReducer,
  updateSwCategoryReducer,
  getSwCategoryListReducer,
  createSwSubCategoryReducer,
  updateSwSubCategoryReducer,
  getSwSubCategoryListReducer,
  createSwQuestionReducer,
  updateSwQuestionReducer,
  getSwQuestionListReducer,
  createInspectionTypeReducer,
  updateInspectionTypeReducer,
  getInspectionTypeListReducer,
  getInspectionTypeFieldsListReducer,
  getBindInspectionTypeFromStateListReducer,
  getInTyFeByInTyListReducer,
  updateInspectionTypeFieldsReducer,
  updateSwQuestionSetReducer,
  updateSwQuestionSetProjectMapReducer,
  cloneSwQuestionSetReducer,
  updateStatusQuestionSetProjectMapReducer,
  getSwInspectionListReducer,
  createSwInspectionReducer,
  updateSwInspectionReducer,
  getSwInspectionDetailReducer,
  deleteSwInspectionReducer,
  getQuestionSetOnProjectReducer,
  getSwInspectorListReducer,
  assignSwInspectorReducer,
  getSwSecondaryInspectorListReducer,
  deleteSwSecondaryInspectionReducer,
  swDashboardcardDetailsReducer,
  createSwInspectionItemCommentReducer,
  createSwInspectionCloseItemReducer,
  createSwOpenItemDetailReducer,
  deferredSwInspectionReducer,
  swOpenItemListReducer,
  getExternalInspectionReducer,
  conductExternalInspectionReducer,
  getInspectionHeaderDetailsReducer,
  getInspectionDailyReportDetailsReducer,
  updateInspectionDailyReportReducer,
  getBulkInspectionDetailsReducer,
  getBulkInspectionFilesReducer,
  getProjectContactsEmailDetailsReducer,
} from "../storm-water/reducers/swInspectionReducer";
import {
  getSwProjectSearchListReducer,
  getSwReferenceDataReducer,
  getSwContactSearchListReducer,
} from "../storm-water/reducers/swCommonReducer";
import {
  newsAddReducer,
  getNewsListReducer,
  getNewsDetailsReducer,
  updateNewsReducer,
  deleteNewsReducer,
} from "./newsReducer";
import {
  addJhaActivityReducer,
  addJhaCategoryReducer,
  addJhaControlReducer,
  addJhaHazardReducer,
  getConductJhaListReducer,
  addJhaTemplateReducer,
  getJhaActivityListReducer,
  getJhaCategoryListReducer,
  getJhaControlDetailsReducer,
  getJhaControlListReducer,
  getJhaControlTypeListReducer,
  getJhaHazardsListReducer,
  getJhaTemplateListReducer,
  updateJhaActivityReducer,
  updateJhaCategoryReducer,
  updateJhaControlReducer,
  updateJhaHAzardReducer,
  updateJhaTemplateStatusReducer,
  addJhaConductReducer,
  getJhaTempleteDetsilsReducer,
  getJhaConductDetailsReducer,
  updateJhaTemplateReducer,
  addJhaSetupReducer,
  getJhaSetupListReducer,
  activeInactiveJHASetupReducer,
  getJHAReferenceDataReducer,
  getJhaSetupDetailsReducer,
  updateJhaSetupReducer,
  getJhaSetupDashboardReducer,
  getSaftyLogJHAConductListReducer,
  getJhaActiveTemplateListReducer,
} from "./jhaReducer";
import {
  dateWiseInspectorSummaryReducer,
  dateWiseSummaryReducer,
  inspectorSummaryReducer,
  organisationMapReducer,
  organisationSummaryReducer,
  perDayInspectorSummaryReducer,
} from "../storm-water/reducers/reportsReducer";

import {
  sequenceLandingProjectpermitListReducer,
  sequenceLandingPermitDetailReducer,
  sequenceLandingSamplingLogsReducer,
  qrSequenceLandingInspectionReducer,
  qrSequenceLandingResourecListReducer,
  qrSequenceLandingInspectionDetailsReducer,
  qrSequenceLandingInspectionTypeFieldReducer,
} from "../reducers/sequenceLandingReducer";

const reducers = combineReducers({
  //admin
  contactList: contactListReducer,
  createContact: createContactReducer,
  deleteContacts: deleteContactsReducer,
  viewContacts: viewContactsReducer,
  updateContact: updateContactReducer,
  resourceList: resourceListReducer,
  resourceDetails: resourceDetailsReducer,
  deleteResource: deleteResourceReducer,
  createResource: createResourceReducer,
  updateResource: updateResourceReducer,
  equipmentList: equipmentListReducer,
  createEquipment: createEquipmentReducer,
  deleteEquipment: deleteEquipmentReducer,
  equipmentDetail: equipmentDetailReducer,
  updateEquipment: updateEquipmentsReducer,
  assetList: assetListReducer,
  vehicleList: vehicleListReducer,
  createDivisions: createDivisionsReducer,
  updateDivisions: updateDivisionsReducer,
  divisionsList: getDivisionsListReducer,
  deleteDivisions: deleteDivisionsReducer,
  viewDivisions: viewDivisionsReducer,
  projectList: projectListReducer,
  createProject: createProjectReducer,
  deleteProject: deleteProjectReducer,
  updateProject: updateProjectReducer,
  //Auth
  login: loginReducer,
  signup: signupReducer,
  onboarding: onboardingReducer,
  verifyMobile: verifyMobileReducer,
  updatedOnboarding: updateOnboardingReducer,
  userDetails: userReducer,
  users: searchUserReducer,
  allUsers: searchAllUserReducer,
  otpSignup: otpSignupReducer,
  //Incident
  createdCase: creatCaseReducer,
  updatedCase: updateCaseReducer,
  incident: incidentReducer,
  incidentList: incidentListReducer,
  casesList: casesListReducer,
  viewCase: viewCaseReducer,
  addIncident: perosnalInjuryReducer,
  updatePersonalInjury: updatePersonalInjuryReducer,
  addVehical: vehicleAccidentReducer,
  addStolenProperty: stolenPropertyReducer,
  addNearMisses: nearMissesReducer,
  addUtilityDamage: utilityDamageReducer,
  getPersonalReport: viewPersonalInjuryReducer,
  getAccidentReport: viewVehicleAccidentReducer,
  getUtilityDamageReport: viewUtilityDamageReducer,
  getNearMissReport: getNearMissesReducer,
  getLossPropertyReport: getStolenPropertyReducer,
  state: stateReducer,
  city: cityReducer,
  fileUpload: createUploadFilesReducer,
  closeInspectionOpenItem: closeInspectionOpenItemReducer,
  inspectionOpenItemDetail: getInspectionOpenItemDetailReducer,
  inspection: inspectionReducer,
  inspectionDetailData: inspectionDetailView,
  inspectionDelete: inspectionDeleteReducer,
  updatedUtilityDamageData: updateUtilityDamageReducer,
  updatedVehicleAccidentData: updateVehicleAccidentReducer,
  updateNearMissesData: updateNearMissesReducer,
  updateStolenPropertyData: updateStolenPropertyReducer,
  reprimand: reprimandFormDataReducer,
  // observation
  addObservationReport: observationReducer,
  getObservationReport: observationDetailsReducer,
  updateObservation: updateObservationReducer,
  openItems: openItemReducer,
  observationList: ListOfObservationReducer,
  inspViewReportList: inspectionViewReducer,
  reprimandView: reprimandViewReducer,
  getRegNumberDat: getRegNumberReducer,
  articlesCatSub: articleCatSubcategoryReducer,
  article: articleReducer,
  getArticlesBasedCatSubcategory: articlesBasedCatSubcategoryReducer,
  getArticleDetails: articlesDetailsReducer,
  tickets: getTicketsReducer,
  createTicket: createTicketReducer,
  userFeedbackOnTicket: postUserFeedbackReducer,
  getTicketDetails: getTicketDetailsReducer,
  getCoursesList: getCoursesListReducer,
  getLessonsList: getLessonsListReducer,
  getUserLessonsList: getUserLessonsListReducer,
  getUserCoursesList: getUserCoursesListReducer,
  getUserCourseView: getUserCourseViewReducer,
  getUserLessonView: getUserLessonViewReducer,
  getCategoryList: getCategoryListReducer,
  createCourses: createCoursesReducer,
  deleteIncidentsData: deleteIncidentsReducer,
  actionListData: actionListReducer,
  closeActionItem: closeActionItemReducer,
  getDashboardTickets: getDashboardTicketsReducer,
  getTicketsAdminDesk: getTicketsAdminDeskReducer,
  getTicketsFeedbackDesk: getTicketsFeedbackDeskReducer,
  postClosedTicket: postClosedTicketReducer,
  postUserAssignTicket: postUserAssignTicketReducer,
  postUpdateSeverityTicket: postUpdateSeverityTicketReducer,
  updateCourse: updateCourseReducer,
  getlessonsDropDownList: getlessonsDropDownListReducer,
  deleteCourse: deleteCourseReducer,
  createLesson: createLessonReducer,
  replyTicket: replyTicketReducer,
  postAddFAQ: postAddFAQReducer,
  createQuiz: createQuizReducer,
  getQuizList: getQuizListReducer,
  postAddAssest: postAddAssestReducer,
  updateLesson: updateLessonReducer,
  deleteLesson: deleteLessonReducer,
  submitQuiz: submitQuizReducer,
  getInspections: getInspectionsReducer,
  publishLesson: publishLessonReducer,
  publishCourse: publishCourseReducer,
  getDropdownDataKeyValue: getDropdownDataKeyValueReducer,
  deleteAsset: deleteAssetReducer,
  postAddVehicle: postAddVehicleReducer,
  deleteVehicle: deleteVehicleReducer,
  GroupListData: userGroupReducer,
  addUserGroupData: addUserGroupReducer,
  getAssignCourseUserList: getAssignCourseUserList,
  userlistData: userReducerList,
  bouncedEmailList: bouncedEmailReducerList,
  getUserListDetsilsData: getUserListDetsilsReducer,
  updateUserListData: updateUserListReducer,
  getRoleListData: getRoleListReducer,
  creatRoleReducerData: creatRoleReducer,
  getAssignListData: getAssignListReducer,
  creatAssignRoleReducerData: creatAssignRoleReducer,
  creatAssignRoleData: creatAssignRoleReducer,
  roleListDetsilsData: getRoleListDetsilsReducer,
  updateRoleListReducerData: updateRoleListReducer,
  deleteUserListData: deleteUserListReducer,
  addUserListData: addUserListReducer,
  getAssetDetails: getAssetDetailsReducer,
  deleteUserGroupData: deleteUserGroupReducer,
  updateUserGroupData: updateUserGroupReducer,
  getUserGroupDetsilsData: getUserGroupDetsilsReducer,
  getKudosLogData: getKudosLogReducer,
  getReprimandLogData: getReprimandLogReducer,
  getViolationData: getViolationLogReducer,
  assignLesson: assignLessonReducer,
  assignCourse: assignCourseReducer,
  getAssignLessonUserList: getAssignLessonUserListReducer,
  deleteRoleReducerData: deleteRoleReducer,
  getUserInRoleListData: getUserInRoleList,
  createContractordata: createContractorReducer,
  getContractorList: getContractorListReducer,
  deleteContractor: deleteContractorReducer,
  viewContractor: viewContractorReducer,
  updateContractor: updateContractorReducer,
  startCourse: startCourseReducer,
  updateAsset: updateAssetReducer,
  //Inspection Configuration
  adminAddUpdateCategory: adminAddUpdateCategoryReducer,
  adminAddUpdateSubcategory: adminAddUpdateSubcategoryReducer,
  addUpdateQuestions: addUpdateQuestionsReducer,
  adminUpdateInspection: adminUpdateInspectionReducer,
  adminAddUpdateInspection: adminAddUpdateInspectionReducer,
  deleteQuestion: deleteQuestionReducer,
  getCategoriesList: getCategoriesListReducer,
  getSubCategoriesList: getSubCategoriesListReducer,
  getQuestionsList: getQuestionsListReducer,
  getQuestionSetList: getQuestionSetListReducer,
  cloneQuestionSet: cloneQuestionSetReducer,
  qestSetDetail: qestSetDetailReducer,
  getAssetList: getAssetListReducer,
  getCredentialListData: getCredentialListReducer,
  createCredentialData: createCredentialReducer,
  getCredentialDetsilsData: getCredentialDetsilsReducer,
  getCredentialViewDetsilsData: getCredentialViewDetsilsReducer,
  updateCredentialData: updateCredentialReducer,
  //getAssignUserListData: getAssignUserListReducer,
  getSearchCredentialData: getSearchCredentialReducer,
  assignCredentialData: assignCredentialReducer,
  updateUserCredentialData: updateUserCredentialReducer,
  //getAssignUserListData: getAssignUserListReducer,
  createUserCredentialData: createUserCredentialReducer,
  getCredentialUserListData: getCredentialUserListReducer,
  getCredentialListByUserData: getCredentialListByUsersReducer,
  getCredentialListByCredentialsData: getCredentialListByCredentialsReducer,
  createAdminCredentialData: createAdminCredentialReducer,
  deleteCredentialReducerData: deleteCredentialReducer,
  createPersonalContractorData: createPersonalContractorReducer,
  updatePersonalContractorData: updatePersonalContractorReducer,
  getPersonalContractorListData: getPersonalContractorListReducer,
  deletePersonalContractorData: deletePersonalContractorReducer,
  viewPersonalContractorData: viewPersonalContractorReducer,
  updateReprimandToClose: updateReprimandToClose,
  updateInspectionAcknowledgement: updateInspectionAcknowledgementReducer,
  getInspectionAcknowledgement: getInspectionAcknowledgementReducer,
  getVehicleDetails: getVehicleDetailsReducer,
  updateVehicle: updateVehicleReducer,
  getProjectDetails: getProjectDetailsReducer,
  leaveGroupData: leaveGroupReducer,
  searchAllGroup: searchAllGroupReducer,
  individualUserGroupData: userGroupListReducer,
  createCategory: createCategoryReducer,
  categoryList: categoryListReducer,
  getClientListData: getClientListReducer,
  createClientData: createClientReducer,
  completeLesson: completeLessonReducer,
  updateClientData: updateClientReducer,
  getClientDetsilsData: getClientDetsilsReducer,
  deleteClientData: deleteClientReducer,
  activeCategory: activeCategoryReducer,
  startLesson: startLessonReducer,
  reprimandCount: reprimandCountReducer,
  createSupportCategory: createSupportCategoryReducer,
  categorySupportList: categorySupportListReducer,
  activeSupportCategory: activeSupportCategoryReducer,
  searchAllClientsReducerData: searchAllClientsReducer,
  categoryUtilityIncidentList: categoryUtilityIncidentListReducer,
  createUtilityIncidentCategory: createUtilityIncidentCategoryReducer,
  activeIncidentCategory: activeIncidentCategoryReducer,
  getAssignAlertUserListReducer: getAssignAlertUserListReducer,
  getQuizDetailReducer: getQuizDetailReducer,
  updateQuiz: updateQuizReducer,
  userConfigurationData: userConfigurationReducerList,
  updateUserConfigurationData: updateUserConfigurationListReducer,
  getUserConfigurationDetsilsData: getUserConfigurationDetsilsReducer,
  updateUserOnboardData: updateUserOnboardReducer,
  updateUserOnboardStatusData: updateUserOnboardStatusReducer,
  //report
  getCredentialReportData: getCredentialReportReducer,
  getLookupCredentialReportData: getLookupCredentialReportReducer,
  getConfiguredCredentialReportData: getConfiguredCredentialReportReducer,
  createToolBoxTalk: createToolBoxTalkReducer,
  getToolBoxTalkView: getToolBoxTalkViewReducer,
  updateToolBoxTalk: updateToolBoxTalkReducer,
  deleteToolBoxTalk: deleteToolBoxTalkReducer,
  getToolSafetyMeetingSearch: getToolSafetyMeetingSearchReducer,
  assignAdminToInstructorTool: assignAdminToInstructorToolReducer,
  getToolBoxTalkList: getToolBoxTalkListReducer,
  getOnboardedContractorListData: getOnboardedContractorListReducer,
  updateOnboardedContractorListData: updateOnboardedContractorListReducer,
  getScheduleMeetingDetail: getScheduleMeetingDetailReducer,
  assignInstructorToUserTool: assignInstructorToUserToolReducer,
  assignSelfInstructorToUserTool: assignSelfInstructorToUserToolReducer,
  getAdminToolBoxTalkList: getAdminToolBoxTalkListReducer,
  getContractorConfigurationDetsilsData:
    getContractorConfigurationDetsilsReducer,
  updateContractorOnboardData: updateContractorOnboardReducer,
  updateContractorOnboardStatusData: updateContractorOnboardStatusReducer,
  NonPrivilegeuserListData: NonPrivilegeuserListReducer,
  deleteNonPrivilegeUserListData: deleteNonPrivilegeUserListReducer,
  addNonPrivilegeUserListData: addNonPrivilegeUserListReducer,
  getNonPrivilegeUserListDetsilsData: getNonPrivilegeUserListDetsilsReducer,
  updateNonPrivilegeUserListData: updateNonPrivilegeUserListReducer,
  verifyAttendee: verifyAttendeeReducer,
  getVerifyAttendeeList: getVerifyAttendeeListReducer,
  deleteScheduleToolBoxTalk: deleteScheduleToolBoxTalkReducer,
  userMarkedCompletedToolBox: userMarkedCompletedToolBoxReducer,
  createExtraUser: createExtraUserReducer,
  getExtraUserList: getExtraUserListReducer,
  getProjectSearchList: getProjectSearchListReducer,
  searchAllUserProjectWise: searchAllUserProjectWiseReducer,
  getTicketCategory: getTicketCategoryReducer,
  closeIncident: closeIncidentReducer,
  getInspectionViolationCounts: getInspectionViolationCountsReducer,
  getItemList: getItemListReducer,
  getAssignTookBoxUser: getAssignTookBoxUserList,
  getLmsReport: getLmsReportReducer,
  getLmsReportCourse: getLmsReportCourseReducer,
  getLmsReportLesson: getLmsReportLessonReducer,
  getPersonalInjuryIncidentReportCount:
    getPersonalInjuryIncidentReportCountReducer,
  getPersonalInjuryIncidentReportList:
    getPersonalInjuryIncidentReportListReducer,
  getSupervisorByProject: getSupervisorByProjectReducer,
  updateUserDashboardCart: updateUserDashboardCartReducer,
  getDashboardCartList: getDashboardCartListReducer,
  getUserDashboardCartList: getUserDashboardCartListReducer,
  deleteFAQ: deleteFAQReducer,
  updateFAQ: updateFAQReducer,
  postSafetyMeetingAlert: postSafetyMeetingAlertReducer,
  updateCategoryConfigation: updateCategoryConfigationReducer,
  updateSupportCategoryConfigation: updateSupportCategoryConfigationReducer,
  updateIncidentCategoryConfigation: updateIncidentCategoryConfigationReducer,
  //SW Inpection
  swQuestionSetList: swQuestionSetListReducer,
  createSwQuestionSet: createSwQuestionSetReducer,
  updateSwQuestionSet: updateSwQuestionSetReducer,
  getSwQuestionSetDetail: getSwQuestionSetDetailReducer,
  updateSwQuestionSetStatus: updateSwQuestionSetStatusReducer,
  createSwQuestionSetProjectMap: createSwQuestionSetProjectMapReducer,
  updateSwQuestionSetProjectMap: updateSwQuestionSetProjectMapReducer,
  getSwQuestionSetProjectMapDetail: getSwQuestionSetProjectMapDetailReducer,
  getSwQuestionSetProjectMapList: getSwQuestionSetProjectMapListReducer,
  createSwCategory: createSwCategoryReducer,
  updateSwCategory: updateSwCategoryReducer,
  getSwCategoryList: getSwCategoryListReducer,
  createSwSubCategory: createSwSubCategoryReducer,
  updateSwSubCategory: updateSwSubCategoryReducer,
  getSwSubCategoryList: getSwSubCategoryListReducer,
  createSwQuestion: createSwQuestionReducer,
  updateSwQuestion: updateSwQuestionReducer,
  getSwQuestionList: getSwQuestionListReducer,
  createInspectionType: createInspectionTypeReducer,
  updateInspectionType: updateInspectionTypeReducer,
  getInspectionTypeList: getInspectionTypeListReducer,
  getInspectionTypeFieldsList: getInspectionTypeFieldsListReducer,
  getBindInspectionTypeFromStateList: getBindInspectionTypeFromStateListReducer,
  getInTyFeByInTyList: getInTyFeByInTyListReducer,
  updateInspectionTypeFieldsMap: updateInspectionTypeFieldsReducer,
  cloneSwQuestionSet: cloneSwQuestionSetReducer,
  updateStatusQuestionSetProjectMap: updateStatusQuestionSetProjectMapReducer,
  getQuestionSetOnProject: getQuestionSetOnProjectReducer,
  swDashboardcardDetails: swDashboardcardDetailsReducer,
  // sw inspection user
  getSwInspectionList: getSwInspectionListReducer,
  createSwInspection: createSwInspectionReducer,
  updateSwInspection: updateSwInspectionReducer,
  getSwInspectionDetail: getSwInspectionDetailReducer,
  deleteSwInspection: deleteSwInspectionReducer,
  getSwInspectorListData: getSwInspectorListReducer,
  assignSwInspectorData: assignSwInspectorReducer,
  getSwSecondaryInspectorListData: getSwSecondaryInspectorListReducer,
  deleteSwSecondaryInspectionData: deleteSwSecondaryInspectionReducer,
  swOpenItemDetail: createSwOpenItemDetailReducer,
  createSwInspectionCloseItem: createSwInspectionCloseItemReducer,
  createSwInspectionItemComment: createSwInspectionItemCommentReducer,
  swOpenItemList: swOpenItemListReducer,
  // strom-water
  siteData: siteReducer,
  updateSiteData: updateSiteReducer,
  getSiteDetsilsData: getSiteDetsilsReducer,
  getSite: getSiteReducer,
  getContactInProjectList: getContactInProjectListReducer,
  createSwProjectData: createSwProjectReducer,
  updateSwProject: updateSwProjectReducer,
  siteListData: siteListReducer,
  swprojectList: swprojectListReducer,
  getSwProjectDetail: getSwProjectDetailReducer,
  addContactInProject: postAddContactInProjectReducer,

  siteListAutoSearch: siteListAutoSearchReducer,
  addPermit: addPermitReducer,
  permitDetails: permitDetailsReducer,
  permitList: getpermitListReducer,
  updatePermit: updatePermitReducer,
  addPermitType: addPermitTypeReducer,
  permitTypeList: getPermitTypeListReducer,
  updatePermitType: updatePermitTypeReducer,
  updateProjectContactStatus: updateProjectContactStatusReducer,
  editOneContactInProject: editOneContactInProjectReducer,
  deleteContactInProject: deleteContactInProjectReducer,
  // Common
  getSwReference: getSwReferenceDataReducer,
  getSwProjectSearchList: getSwProjectSearchListReducer,
  getSwContactSearchhList: getSwContactSearchListReducer,
  generalPermitList: getGeneralPermitTypeListReducer,
  createSwProjectSampleLog: createSwProjectSampleReducer,
  swprojectSampleList: swprojectSampleListReducer,
  updateGeneralPermit: updateGeneralPermitReducer,
  getPermitType: getPermitTypeReducer,
  // jha
  addJhaCategory: addJhaCategoryReducer,
  jhaCategoryList: getJhaCategoryListReducer,
  updateJhaCategory: updateJhaCategoryReducer,
  addJhaActivity: addJhaActivityReducer,
  getJhaActivityList: getJhaActivityListReducer,
  updateJhaActivity: updateJhaActivityReducer,
  addJhaHazard: addJhaHazardReducer,
  getJhaHazardsList: getJhaHazardsListReducer,
  updateJhaHazard: updateJhaHAzardReducer,
  getJhaControlList: getJhaControlListReducer,
  addJhaControl: addJhaControlReducer,
  updateJhaControl: updateJhaControlReducer,
  getConductJhaListData: getConductJhaListReducer,
  addJhaTemplate: addJhaTemplateReducer,
  getJhaTemplateList: getJhaTemplateListReducer,
  updateJhaTemplateStatus: updateJhaTemplateStatusReducer,
  deferredSwInspection: deferredSwInspectionReducer,
  addJhaConductData: addJhaConductReducer,
  getJhaTempleteDetsilsData: getJhaTempleteDetsilsReducer,
  getJhaControlTypeList: getJhaControlTypeListReducer,
  getJhaControlDetails: getJhaControlDetailsReducer,
  getJhaConductDetailsData: getJhaConductDetailsReducer,
  updateJhaTemplate: updateJhaTemplateReducer,
  addJhaSetup: addJhaSetupReducer,
  getJhaSetupList: getJhaSetupListReducer,
  activeInactiveJHASetup: activeInactiveJHASetupReducer,
  getJHAReferenceData: getJHAReferenceDataReducer,
  getJhaSetupDetails: getJhaSetupDetailsReducer,
  updateJhaSetup: updateJhaSetupReducer,
  getJhaSetupDashboard: getJhaSetupDashboardReducer,
  getJhaActiveTemplateList: getJhaActiveTemplateListReducer,
  getSaftyLogJHAConductList: getSaftyLogJHAConductListReducer,
  getAssetDynomoList: getAssetDynomoListReducer,
  swrainFallData: swrainFallDataReducer,
  deleteSWProject: deleteSWProjectReducer,
  getExportItemListDatas: getExportItemListReducer,
  convertNonPrivilegeUserListData: convertNonPrivilegeUserListReducer,
  newsAdd: newsAddReducer,
  getNewsList: getNewsListReducer,
  getNewsDetails: getNewsDetailsReducer,
  updateNews: updateNewsReducer,
  deleteNews: deleteNewsReducer,
  convertNonPrivilegeContactListData: convertNonPrivilegeContactListReducer,
  getExternalInspection: getExternalInspectionReducer,
  conductExternalInspection: conductExternalInspectionReducer,
  getInspectionHeaderDetails: getInspectionHeaderDetailsReducer,
  getInspectionDailyReportDetails: getInspectionDailyReportDetailsReducer,
  updateInspectionDailyReport: updateInspectionDailyReportReducer,
  recreateUsersData: recreateUsersReducer,
  deleteCase: deleteCaseReducer,
  getProjectContactsEmailDetails: getProjectContactsEmailDetailsReducer,
  getInspectionOpenItemCount: getInspectionOpenItemCountReducer,
  getAssetOnUser: getAssetOnUserReducer,
  getSupervisorOnAsset: getSupervisorOnAssetReducer,
  getBulkInspectionDetails: getBulkInspectionDetailsReducer,
  getBulkInspectionFiles: getBulkInspectionFilesReducer,
  checkUserInfo: checkUserInfoReducer,
  organisationSummaryData: organisationSummaryReducer,
  inspectorSummaryData: inspectorSummaryReducer,
  dateWiseSummaryData: dateWiseSummaryReducer,
  perDayInspectorSummaryData: perDayInspectorSummaryReducer,
  dateWiseInspectorSummaryData: dateWiseInspectorSummaryReducer,
  organisationMapData: organisationMapReducer,
  completedLessonStatuscheckData: getCompletedLessonStatuscheckReducer,
  NotifiactionLoginData: NotifiactionLoginReducer,
  NotifiactionLogoutData: NotifiactionLogoutReducer,
  NotifiactionListData: NotifiactionListReducer,
  updateNotifiactionListData: updateNotifiactionListReducer,
  unAssignLesson: unAssignLessonReducer,
  unAssignCourse: unAssignCourseReducer,
  getCompletedCourseStatuscheck: getCompletedCourseStatuscheckReducer,
  notifiactionCountData: notifiactionCountReducer,
  updateNotifiactionCountData: updateNotifiactionCountReducer,
  unEnrollLesson: unEnrollLessonReducer,
  unEnrollCourse: unEnrollCourseReducer,
  searchSubcontractor: searchSubcontractorReducer,
  createContractorClientWise: createContractorClientWiseReducer,
  userByRoleListData: userByRoleListReducer,
  searchLessons: searchLessonsReducer,
  searchCourses: searchCoursesReducer,
  MappedGlobalLessonList: MappedGlobalLessonListReducer,
  MappedGlobalCoursesList: MappedGlobalCoursesListReducer,
  specifyLessontoUserViewClientData: specifyLessontoUserViewClientDataReducer,
  specifyLessontoUserViewLessonData: specifyLessontoUserViewLessonDataReducer,
  specifyCoursetoUserViewClientData: specifyCoursetoUserViewClientDataReducer,
  specifyCoursetoUserViewCourseData: specifyCoursetoUserViewCourseDataReducer,
  getUserAssetList: getUserAssetListReducer,
  getSyncWithProcoreData: getSyncWithProcoreReducer,
  userSyncWithProcoreData: userSyncWithProcoreReducer,
  getProcoreCompanyData: getProcoreCompanyReducer,
  procoreProjectsListData: procoreProjectsListReducer,
  syncProcoreProjectsListData: syncProcoreProjectsListReducer,
  inspectionFieldsMap: inspectionFieldsMapReducer,
  updateInspectionFieldsMap: updateInspectionFieldsMapReducer,
  inspectionReportAssetDetails: inspectionReportAssetDetailsReducer,
  inspecionReport: getInspecionReportReducer,
  openItemPhotos: getOpenItemPhotosReducer,
  getLoginOTP: getLoginOTPReducer,
  getIncidentReport: getIncidentReportReducer,
  //Incident report
  incidentReportInjuredBodyPart: getIncidentReportInjuredBodyPartReducer,
  incidentReportInjBodyPartList: getIncidentReportInjuredBodyPartListReducer,
  getIncidentReportMostCommonInjury: getIncidentReportMostCommonInjuryReducer,
  incidentReportMostCommonInjList: getIncidentReportMostCommonInjuryListReducer,
  incReportMostCommonDayOfInjury: getIncReportMostCommonDayOfInjuryReducer,
  incUserMostIncident: getIncidentReportUserWithMostIncidentReducer,
  incUserMostIncidentList: getIncidentReportUserWithMostIncidentListReducer,
  incUserMostActionItem: getIncidentReportUserWithMostActionItemsReducer,
  incUsrMostActionItemList: getIncidentReportUserWithMostActionItemsListReducer,
  projectwithmostincidents: projectwithmostincidentsReducer,
  projectwithmostincidentsList: projectwithmostincidentsListReducer,
  vehiclewithmostincidents: vehiclewithmostincidentsReducer,
  vehiclewithmostincidentsList: vehiclewithmostincidentsListReducer,
  getLoginOTPUserVerify: getLoginOTPUserVerifyReducer,
  getLmsDashboardReport: getLmsDashboardReportReducer,
  getLmsDashboardReportDetails: getLmsDashboardReportDetailsReducer,
  OSHAEstablishmentList: getOSHAEstablishmentListReducer,
  addEstablishment: addEstablishmentReducer,
  updateEstablishment: updateEstablishmentReducer,
  updateEstablishmentStatus: updateEstablishmentStatusReducer,
  establishmentDetail: getEstablishmentDetailReducer,
  getOshaLog: getOshaLogReducer,
  getOshaDetails: getOshaDetailsReducer,
  addOshaDetails: addOshaDetailsReducer,
  getListAddedInOSHA: getListAddedInOSHAReducer,
  getSubmitDetailsToOsha: getSubmitDetailsToOshaReducer,
  mySafetyMeetingList: getMySafetyMeetingListReducer,
  getScheduleInstructorDetails: getScheduleInstructorDetailsReducer,
  getScheduleAttendeesDetails: getScheduleAttendeesDetailsReducer,
  getScheduleSaftyMeetingDetails: getScheduleSaftyMeetingDetailsReducer,
  updateAdminAssignInstructors: updateAdminAssignInstructorsReducer,
  sequenceLandingProjectpermitList: sequenceLandingProjectpermitListReducer,
  sequenceLandingPermitDetail: sequenceLandingPermitDetailReducer,
  sequenceLandingSamplingLogs: sequenceLandingSamplingLogsReducer,
  qrSequenceLandingInspectionData: qrSequenceLandingInspectionReducer,
  qrSequenceLandingResourecListData: qrSequenceLandingResourecListReducer,
  qrSequenceLandingInspectionDetailsData:
    qrSequenceLandingInspectionDetailsReducer,
  qrSequenceLandingInspectionTypeFieldData:
    qrSequenceLandingInspectionTypeFieldReducer,
  addGetOSHALog: addGetOSHALogReducer,
});
export default reducers;
